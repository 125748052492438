// import "./App.css";
import React from "react";
import ReactDOMServer from "react-dom/server";
import ReactTooltip from "react-tooltip";

import ChartBg from "./ChartBg";
import PaymentItem from "./PaymentItem";
import HorizontalTalContainer from "./HorizontalTabContainer";
import CollapsingDiv from "./Component/CollpasingDiv";
import Strings from "./Strings";
import KathmanduMap from "./Component/KathmanduMap";
import DamakMap from "./Component/DamakMap";
import DangMap from "./Component/DangMap";

/**
 * TODO: remove duplicate codes and strings.
 * TODO: need to add language selection
 */
class App extends React.Component {
  constructor(props) {
    super(props);

    this.pageMain = React.createRef();
    this.pageOurServices = React.createRef();
    this.pageJoinUs = React.createRef();
    this.pagePayments = React.createRef();
    this.pageSupport = React.createRef();
    this.pageContactUsSection = React.createRef();
    this.pageFooter = React.createRef();
    // this.scrollContainerRef = React.createRef();

    this.state = {
      lang: Strings.nep,
      selectedBranch: 1,
      selectedSupportOption: 1,
      navpanelCss: "navpanel-enter",
      dotpanelCss: "navpanel-exit",
      showRegistration: true,
      jfCss: "",
      content: undefined,
      contentCss: "content-enter",
      sidePanel: undefined,
      sidePanelCss: "sidepanel-enter",
      activeId: undefined,
      // show: false,

      paymentsWidth: "50%",

      scrollIndex: 0,
      pages: [
        this.pageMain,
        this.pageOurServices,
        this.pageJoinUs,
        this.pagePayments,
        this.pageSupport,
        this.pageContactUsSection,
        this.pageFooter,
      ],
      vSupportLink:
        "c29tZV9zYWx0Ojo6Omh0dHBzOi8vaW52aXRlLnZpYmVyLmNvbS8/ZzI9QVFBYktjdXJVbVBKYUUyNUNFMEtrcSUyQmpvZzRTTmxFN3ZaRFBnRmlDUUNwcXlvYXRjMWxxeXZsSzdHRlpVZWNU",
    };
    window.bg_scroll = true;

    this.frame_width = "180";
    this.frame_height = "100";

    this.changeTab = this.changeTab.bind(this);
    this.renderPayments = this.renderPayments.bind(this);
    this.renderSupport = this.renderSupport.bind(this);
    this.renderContactUsSection = this.renderContactUsSection.bind(this);

    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.scrollPage = this.scrollPage.bind(this);
    this.handlePaymentClick = this.handlePaymentClick.bind(this);

    this.handlePageChangeOpenAccount =
      this.handlePageChangeOpenAccount.bind(this);
    this.handlePageChangePayments = this.handlePageChangePayments.bind(this);

    this.renderLanguageToggle = this.renderLanguageToggle.bind(this);

    this.bindMouseWheel = this.bindMouseWheel.bind(this);
    this.unBindMouseWheel = this.unBindMouseWheel.bind(this);
    this.mouseEnterScrollerDiv = this.mouseEnterScrollerDiv.bind(this);
    this.mouseLeaveScrollerDiv = this.mouseLeaveScrollerDiv.bind(this);

    this.renderHowToConnectIps = this.renderHowToConnectIps.bind(this);
    this.renderHowToEsewa = this.renderHowToEsewa.bind(this);
    this.renderHowToKhalti = this.renderHowToKhalti.bind(this);
    this.renderHowToImePay = this.renderHowToImePay.bind(this);
    this.renderHowToFonePay = this.renderHowToFonePay.bind(this);
    this.renderHowToBankTransfer = this.renderHowToBankTransfer.bind(this);

    this.redirectToVSupport = this.redirectToVSupport.bind(this);
  }

  bindMouseWheel() {
    window.addEventListener("wheel", this.handleScroll, { passive: false });
  }
  unBindMouseWheel() {
    window.removeEventListener("wheel", this.handleScroll);
  }

  componentDidMount() {
    this.bindMouseWheel();
    ChartBg.run();
  }

  componentWillUnmount() {
    this.unBindMouseWheel();
  }

  scrollPage(index) {
    window.bg_scroll = index == 0;
    this.setState(
      {
        navpanelCss: index == 0 ? "navpanel-enter" : "navpanel-exit",
        dotpanelCss: index == 0 ? "navpanel-exit" : "navpanel-enter",
      },
      () => {
        this.state.pages[index].current.scrollIntoView({ behavior: "smooth" });
        setTimeout(
          () => this.setState({ scrollIndex: index }),
          parseInt(
            getComputedStyle(document.documentElement).getPropertyValue(
              "--anim-duration-long"
            )
          )
        );
      }
    );
  }

  handleScroll(event) {
    event.preventDefault();
    event.stopPropagation();
    const currentTime = new Date().getTime();
    if (!this.state.lastScroll || currentTime - this.state.lastScroll > 300) {
      if (event.deltaY < 0)
        this.state.scrollIndex = Math.max(0, this.state.scrollIndex - 1);
      if (event.deltaY > 0)
        this.state.scrollIndex = Math.min(
          this.state.pages.length - 1,
          this.state.scrollIndex + 1
        );
      this.scrollPage(this.state.scrollIndex);
      this.state.lastScroll = currentTime;
    }
  }

  changeTab = (id, renderMethod, sidePanelMethod) => {
    if (id == this.state.activeId) return;
    const navHeight = getComputedStyle(
      document.documentElement
    ).getPropertyValue("--nav-bar-height-collapsed");
    document.documentElement.style.setProperty("--nav-bar-height", navHeight);
    document.documentElement.style.setProperty("--nav-tools-align", "16px");
    document.documentElement.style.setProperty("--nav-text-color", "#08644c");
    document.documentElement.style.setProperty("--nav-bg-color", "#08644c");
    document.documentElement.style.setProperty("--nav-bar-z-index", "1000");

    const animationDuration = this.state.content
      ? "--anim-duration"
      : "--anim-duration-long";

    this.setState(
      {
        showRegistration: false,
        jfCss: "collapse",
        contentCss: "content-exit",
        sidePanelCss: "sidepanel-exit",
        activeId: id,
      },
      () =>
        setTimeout(
          () =>
            this.setState({
              content: renderMethod(),
              contentCss: "content-enter",
              sidePanel: sidePanelMethod ? sidePanelMethod() : undefined,
              sidePanelCss: "sidepanel-enter",
            }),
          parseInt(
            getComputedStyle(document.documentElement).getPropertyValue(
              animationDuration
            )
          )
        )
    );
  };

  renderTitle(title, isWhite) {
    return (
      <div className={`section-title ${isWhite ? "white" : ""}`}>
        {title}
        <div className={`hr ${isWhite ? "white" : ""}`}></div>
      </div>
    );
  }

  renderImgButton(icon, link, label) {
    return (
      <div
        className="img-button"
        onClick={() => window.open(link)}
        style={{ marginLeft: "12px", marginRight: "12px" }}
      >
        <img className="button-icon" src={icon} />
        {label}
      </div>
    );
  }

  renderEmail(email, className, subject, body) {
    let mailto = `mailto:${email}`;
    let params = [];
    if (subject) {
      params.push(`subject=${subject}`);
    }
    if (body) {
      params.push(`body=${body}`);
    }
    if (params.length > 0) {
      mailto = `${mailto}?${params.join("&")}`;
    }
    return (
      <a href={mailto} className={className}>
        {email}
      </a>
    );
  }

  renderPhone(phoneNumber, className) {
    return (
      <a href={`tel:${phoneNumber}`} className={className}>
        {phoneNumber}
      </a>
    );
  }

  renderLocation(location, className) {
    const locationMapping = {
      Kathmandu: 1,
      Damak: 2,
      Dang: 3,
    };
    return (
      <div
        className={className}
        style={{ fontSize: "18px" }}
        onClick={() => {
          this.handlePageChange(5);
          this.handleBranchButtonClick(locationMapping[location]);
        }}
      >
        <img
          src="/location.png"
          style={{ filter: "invert(1)" }}
          className="location-icon"
        />

        {location}
      </div>
    );
  }

  renderPhoneNumbers(numbers, className) {
    return (
      <div>
        {numbers.map((number) => {
          return (
            <div key={number} style={{ marginTop: "10px" }}>
              <img
                src="/phone.png"
                style={{ filter: "invert(1)" }}
                className="contact-icon"
              />
              <a href={`tel:${number}`} className={className}>
                {number}
              </a>
            </div>
          );
        })}
      </div>
    );
  }
  renderPhoneDetails(phoneNumbers, className) {
    return (
      <div style={{ display: "flex" }}>
        {Object.keys(phoneNumbers).map((location, index) => {
          return (
            <div key={index}>
              {this.renderLocation(location, className)}
              {this.renderPhoneNumbers(phoneNumbers[location], className)}
            </div>
          );
        })}
      </div>
    );
  }

  // renderLocation(locationName, className, marginLeft) {
  //   return (
  //     <span className={className} style={{ marginLeft }}>
  //       <img
  //         src="/location.png"
  //         style={{ filter: "invert(1)" }}
  //         className="location-icon"
  //       />
  //       {locationName}
  //     </span>
  //   );
  // }

  // Update renderContactUs method
  renderContactUs() {
    const contactInfo = {
      Kathmandu: ["01-5356099", "01-5348202", "01-5312072"],
      Damak: ["023-570540", "023-578540"],
      Dang: ["082-590172", "082-590173"],
    };
    return (
      <div className="contactUs contact-enter">
        {this.renderPhoneDetails(contactInfo, "contact")}
      </div>
    );
  }

  renderLanguageToggle() {
    if (this.state.lang == Strings.eng) {
      return (
        <div
          className="nep-lang"
          onClick={() => this.setState({ lang: Strings.nep })}
        >
          नेपाली
        </div>
      );
    } else {
      return (
        <div
          className="nep-lang"
          onClick={() => this.setState({ lang: Strings.eng })}
        >
          English
        </div>
      );
    }
  }

  renderTerroristSanctionList() {
    return (
      <div className="add-info-item">
        <div className="add-info-title">Terrorist Sanction List</div>
        {/* <div
          className="add-info-list"
          onClick={() =>
            window.open(
              "https://www.un.org/securitycouncil/content/un-sc-consolidated-list"
            )
          }
        >
          UN Security Council
        </div> */}
        <div
          className="add-info-list"
          onClick={() =>
            window.open("https://www.moha.gov.np/page/sanction-list")
          }
        >
          Targeted Sanction List
        </div>
        {/* <div
          className="add-info-list"
          onClick={() => window.open("https://apgml.org/")}
        >
          APG Sanction List
        </div> */}
      </div>
    );
  }

  renderGrievanceOfficer() {
    let number = 9847773155;
    return (
      <div className="add-info-item">
        <div className="add-info-title">Grievance Officer</div>
        <div className="add-info-list">Nirmala Magar </div>
        <div className="add-info-list">
          <a href={`tel:${number}`} className="contact">
            {number}
          </a>
        </div>
      </div>
    );
  }

  renderAdditionalInfo() {
    return (
      <div className="add-info">
        {this.renderTerroristSanctionList()}
        {this.renderGrievanceOfficer()}
      </div>
    );
  }

  renderJfsRegistration() {
    if (!this.state.showRegistration) return <></>;
    return (
      <div className={`jfsecurities-registration`}>
        {this.state.lang.jfRegistration[0]} ||{" "}
        {this.state.lang.jfRegistration[1]}
      </div>
    );
  }

  renderJfsLogo() {
    return (
      <div>
        <div className={`jfsecurities ${this.state.jfCss}`}>
          <img className="logo" src="logo.png" />
          {this.state.lang.jfLogo[0]}
        </div>
        {this.renderJfsRegistration()}
      </div>
    );
  }

  handlePageChange(idx) {
    this.scrollPage(idx);
  }

  handlePageChangeOpenAccount() {
    this.handlePageChange(2);
  }

  handlePageChangePayments() {
    this.handlePageChange(3);
  }

  renderNavOptions() {
    return (
      <>
        <div
          className={`nav-container ${this.state.navpanelCss}`}
          style={{ position: "absolute", right: "12%", color: "white" }}
        >
          <h3
            className="nav"
            onClick={() => window.open("https://navyaadvisors.com/broker-login?id=26307642-e153-4b9d-bf36-29557a5be78b")}
          >
            {this.state.lang.navOptions.loginZipline}
          </h3>
          <h3
            className="nav"
            onClick={() => window.open("https://tms07.nepsetms.com.np/")}
          >
            {this.state.lang.navOptions.loginTms}
          </h3>
          <h3
            className="nav"
            onClick={() => window.open("https://meroshare.cdsc.com.np/#/login")}
          >
            {this.state.lang.navOptions.loginMeroShare}
          </h3>
          <h3
            className="nav nav-highlighted"
            onClick={this.handlePageChangeOpenAccount}
          >
            {this.state.lang.navOptions.openAccount}
          </h3>
          <h3 className="nav" onClick={this.handlePageChangePayments}>
            {this.state.lang.navOptions.makePayment}
          </h3>
          <h3 className="nav" onClick={() => this.handlePageChange(4)}>
            {this.state.lang.navOptions.faqs}
          </h3>
          <h3 className="nav" onClick={() => this.handlePageChange(5)}>
            {this.state.lang.navOptions.contactUs}
          </h3>
        </div>
        <div
          className={`dot-container ${this.state.dotpanelCss}`}
          style={{ position: "fixed", right: "2%", color: "white" }}
        >
          <div className="dot-body">
            <div
              className={`dot ${this.state.scrollIndex == 1 ? "active" : ""}`}
              onClick={() => this.handlePageChange(1)}
            ></div>
          </div>
          <div className="dot-body">
            <div
              className={`dot ${this.state.scrollIndex == 2 ? "active" : ""}`}
              onClick={this.handlePageChangeOpenAccount}
            ></div>
          </div>
          <div className="dot-body">
            <div
              className={`dot ${this.state.scrollIndex == 3 ? "active" : ""}`}
              onClick={this.handlePageChangePayments}
            ></div>
          </div>
          <div className="dot-body">
            <div
              className={`dot ${this.state.scrollIndex == 4 ? "active" : ""}`}
              onClick={() => this.handlePageChange(4)}
            ></div>
          </div>
          <div className="dot-body">
            <div
              className={`dot ${this.state.scrollIndex == 5 ? "active" : ""}`}
              onClick={() => this.handlePageChange(5)}
            ></div>
          </div>
        </div>
      </>
    );
  }

  renderMainPage() {
    return (
      <div className="nav-bar">
        {this.renderJfsLogo()}
        {this.renderContactUs()}
        {this.renderNavOptions()}
      </div>
    );
  }

  renderMainPageFooter() {
    return (
      <div className="main-footer">
        {this.renderAdditionalInfo()}
        {this.renderLanguageToggle()}
      </div>
    );
  }

  renderOurServicesItem(icon, title, caption) {
    return (
      <div className="ourservices-item">
        <div>
          <img className="ourservices-item-icon" src={icon} />
        </div>
        <div className="ourservices-item-title">{title}</div>
        <div className="ourservices-item-caption">{caption}</div>
      </div>
    );
  }

  renderOurServices() {
    return (
      <>
        {this.renderTitle(this.state.lang.ourServices.ourServices)}
        <div className="ourservices-items">
          {this.renderOurServicesItem(
            "/our_services_brokering.png",
            this.state.lang.ourServices.brokeringService,
            this.state.lang.ourServices.brokeringServiceCaption
          )}
          {this.renderOurServicesItem(
            "/our_services_dp.png",
            this.state.lang.ourServices.dpService,
            this.state.lang.ourServices.dpServiceCaption
          )}
          {this.renderOurServicesItem(
            "/our_services_solutions.png",
            this.state.lang.ourServices.marketSolutions,
            this.state.lang.ourServices.marketSolutionsCaption
          )}
        </div>
      </>
    );
  }

  renderOnlineAccount() {
    return (
      <div style={{ marginLeft: "auto", marginRight: "auto" }}>
        {this.renderImgButton(
          "/logo_tms.png",
          "https://tms07.nepsetms.com.np/client-registration",
          this.state.lang.joinUs.openAccount
        )}
      </div>
    );
  }

  renderOpenDmatAccount() {
    return (
      <>
        {this.renderImgButton(
          "/download_icon.png",
          "/downloads/dmat_individual_account.pdf",
          this.state.lang.joinUs.openDmat.individualDmatForm
        )}
        {this.renderImgButton(
          "/download_icon.png",
          "/downloads/dmat_meroshare.pdf",
          this.state.lang.joinUs.openDmat.meroshareForm
        )}
        <div className="flex-break"></div>
        <div style={{ fontSize: "0.95rem", marginTop: "12px" }}>
          {this.state.lang.joinUs.openDmat.mailDocumentsCaption[0]}{" "}
          {this.renderEmail(
            "operations@jfsecurities.com",
            undefined,
            "Open New Dmat Account",
            "Hi,%0A%0AI want to open a new Dmat account. Please find the KYC and Meroshare forms attached to this mail."
          )}{" "}
          {this.state.lang.joinUs.offlineAccount.mailDocumentsCaption[1]}
        </div>
      </>
    );
  }

  renderOfflineAccount() {
    return (
      <>
        {this.renderImgButton(
          "/download_icon.png",
          "/downloads/kyc_individual_2023.pdf",
          this.state.lang.joinUs.offlineAccount.individualKycForm
        )}
        {this.renderImgButton(
          "/download_icon.png",
          "/downloads/kyc_institution_2023.pdf",
          this.state.lang.joinUs.offlineAccount.institutionKycForm
        )}
        <div className="flex-break"></div>
        <div style={{ fontSize: "0.95rem", marginTop: "12px" }}>
          {this.state.lang.joinUs.offlineAccount.mailDocumentsCaption[0]}{" "}
          {this.renderEmail(
            "operations@jfsecurities.com",
            undefined,
            "Open New Account",
            "Hi,%0A%0AI want to open a new trading account. Please find the KYC form attached to this mail."
          )}{" "}
          {this.state.lang.joinUs.offlineAccount.mailDocumentsCaption[1]}
        </div>
      </>
    );
  }
  renderUpdateAccount() {
    return (
      <>
        {this.renderImgButton(
          "/download_icon.png",
          "/downloads/kyc_individual.pdf",
          this.state.lang.joinUs.updateAccount.individualKycForm
        )}
        {this.renderImgButton(
          "/download_icon.png",
          "/downloads/kyc_institution.pdf",
          this.state.lang.joinUs.updateAccount.institutionKycForm
        )}
        <div className="flex-break"></div>
        <div style={{ fontSize: "0.95rem", marginTop: "12px" }}>
          {this.state.lang.joinUs.updateAccount.mailDocumentsCaption[0]}{" "}
          {this.renderEmail(
            "operations@jfsecurities.com",
            undefined,
            "KYC UPDATE",
            "Hi,%0A%0AI want to update my old account. My client id is xxxxxxxxxx. Please find the KYC form attachd to this mail."
          )}{" "}
          {this.state.lang.joinUs.updateAccount.mailDocumentsCaption[1]}{" "}
          <b>{this.state.lang.joinUs.updateAccount.mailDocumentsCaption[2]}</b>{" "}
          {this.state.lang.joinUs.updateAccount.mailDocumentsCaption[3]}
        </div>
      </>
    );
  }

  renderRequiredDocuments() {
    return (
      <div>
        <h4 className="openAccount-requiredDocs-title">
          {this.state.lang.joinUs.requiredDocs.requiredDocuments}
        </h4>
        <ol>
          <li>{this.state.lang.joinUs.requiredDocs.adultList[0]}</li>
          <li>{this.state.lang.joinUs.requiredDocs.adultList[1]}</li>
          <li>{this.state.lang.joinUs.requiredDocs.adultList[2]}</li>
          <li>{this.state.lang.joinUs.requiredDocs.adultList[3]}</li>
          <li>{this.state.lang.joinUs.requiredDocs.adultList[4]}</li>
          <li>{this.state.lang.joinUs.requiredDocs.adultList[5]}</li>
          <li>{this.state.lang.joinUs.requiredDocs.adultList[6]}</li>
        </ol>
        <h4 className="openAccount-requiredDocs-title">
          {this.state.lang.joinUs.requiredDocs.requriedMinorDocuments}
        </h4>
        <ol>
          <li>{this.state.lang.joinUs.requiredDocs.minorList[0]}</li>
          <li>{this.state.lang.joinUs.requiredDocs.minorList[1]}</li>
          <li>{this.state.lang.joinUs.requiredDocs.minorList[2]}</li>
        </ol>
      </div>
    );
  }
  renderOpenAccount() {
    return (
      <>
        <div className="openAccount-leftPanel">
          <img src="/open_account.png" />
        </div>
        <div className="openAccount-rightPanel">
          <div style={{ width: "50%" }}>
            {this.renderTitle(this.state.lang.joinUs.joinUs)}
            <div
              className="button"
              onClick={() =>
                window.open("https://tms07.nepsetms.com.np/client-registration")
              }
            >
              {this.state.lang.joinUs.openAccountOnline}
            </div>
            <HorizontalTalContainer
              tabs={[
                {
                  title: this.state.lang.joinUs.tabs.openDmat,
                  content: this.renderOpenDmatAccount(),
                },
                {
                  title: this.state.lang.joinUs.tabs.downloadForm,
                  content: this.renderOfflineAccount(),
                },
                {
                  title: this.state.lang.joinUs.tabs.updateKyc,
                  content: this.renderUpdateAccount(),
                },
                {
                  title: this.state.lang.joinUs.tabs.required,
                  content: this.renderRequiredDocuments(),
                },
              ]}
              default={4}
            />
          </div>
        </div>
      </>
    );
  }

  handlePaymentClick(paymentHelpContent) {
    this.setState({ paymentHelpContent });
  }

  renderHowToConnectIps() {
    return (
      <div
        style={{
          width: "100%",
          fontSize: "1.5rem",
          paddingTop: "32px",
          paddingBottom: "32px",
          paddingLeft: "20%",
          paddingRight: "20%",
        }}
      >
        <div style={{ fontSize: "1.5rem" }}>
          {this.state.lang.howToConnectIps.connectIps}
        </div>
        <div className="hr white"></div>
        <iframe
          width="100%"
          height={150}
          src="https://www.youtube.com/embed/rvlEOxHgA28"
          frameborder="0"
          allow="accelerometer"
          allowfullscreen="allowfullscreen"
        />
        <ol style={{ textAlign: "left", paddingLeft: "15px" }}>
          <li>{this.state.lang.howToConnectIps.steps[0]}</li>
          <li>{this.state.lang.howToConnectIps.steps[1]}</li>
          <li>
            {this.state.lang.howToConnectIps.steps[2][0]}{" "}
            <code>{this.state.lang.howToConnectIps.steps[2][1]}</code>
          </li>
          <li>{this.state.lang.howToConnectIps.steps[3]}</li>
          <li>{this.state.lang.howToConnectIps.steps[4]}</li>
          <li>
            {this.state.lang.howToConnectIps.steps[5][0]}{" "}
            <code>{this.state.lang.howToConnectIps.steps[5][1]}</code>
          </li>
          <li>{this.state.lang.howToConnectIps.steps[6]}</li>
        </ol>
        <div
          className="button"
          onClick={() => window.open("https://login.connectips.com/")}
        >
          {this.state.lang.howToConnectIps.gotoConnectIps}
        </div>
      </div>
    );
  }

  renderHowToEsewa() {
    return (
      <div
        style={{
          width: "100%",
          fontSize: "1.5rem",
          paddingTop: "32px",
          paddingBottom: "32px",
          paddingLeft: "20%",
          paddingRight: "20%",
        }}
      >
        <div style={{ fontSize: "1.5rem" }}>
          {this.state.lang.howToEsewa.esewa}
        </div>
        <div className="hr white"></div>
        {/* <iframe width="100%" height={150} src="https://www.youtube.com/embed/tgbNymZ7vqY" frameborder="0" allow="accelerometer" allowfullscreen="allowfullscreen" /> */}
        <ol style={{ textAlign: "left", paddingLeft: "15px" }}>
          <li>{this.state.lang.howToEsewa.steps[0]}</li>
          <li>
            {this.state.lang.howToEsewa.steps[1][0]}{" "}
            <code>{this.state.lang.howToEsewa.steps[1][1]}</code>{" "}
            {this.state.lang.howToEsewa.steps[1][2]}{" "}
            <code>{this.state.lang.howToEsewa.steps[1][3]}</code>
          </li>
          <li>{this.state.lang.howToEsewa.steps[2]}</li>
          <li>{this.state.lang.howToEsewa.steps[3]}</li>
        </ol>
        <div
          className="button"
          onClick={() =>
            window.open(
              "https://esewa.com.np/#/make_payment/NP-ES-TMSINAPP/Trade%20Management%20System%20(TMS)"
            )
          }
        >
          {this.state.lang.howToEsewa.gotoEsewa}
        </div>
      </div>
    );
  }
  renderHowToKhalti() {
    return (
      <div
        style={{
          width: "100%",
          fontSize: "1.5rem",
          paddingTop: "32px",
          paddingBottom: "32px",
          paddingLeft: "20%",
          paddingRight: "20%",
        }}
      >
        <div style={{ fontSize: "1.5rem" }}>
          {this.state.lang.howToKhalti.khalti}
        </div>
        <div className="hr white"></div>
        {/* <iframe width="100%" height={150} src="https://www.youtube.com/embed/tgbNymZ7vqY" frameborder="0" allow="accelerometer" allowfullscreen="allowfullscreen" /> */}
        <ol style={{ textAlign: "left", paddingLeft: "15px" }}>
          <li>{this.state.lang.howToKhalti.steps[0]}</li>
          <li>
            {this.state.lang.howToKhalti.steps[1][0]}{" "}
            <code>{this.state.lang.howToKhalti.steps[1][1]}</code>{" "}
            {this.state.lang.howToKhalti.steps[1][2]}{" "}
            <code>{this.state.lang.howToKhalti.steps[1][3]}</code>
          </li>
          <li>{this.state.lang.howToKhalti.steps[2]}</li>
          <li>{this.state.lang.howToKhalti.steps[3]}</li>
        </ol>
        <div
          className="button"
          onClick={() => window.open("https://web.khalti.com/")}
        >
          {this.state.lang.howToKhalti.gotoKhalti}
        </div>
      </div>
    );
  }

  renderHowToImePay() {
    return (
      <div
        style={{
          width: "100%",
          fontSize: "1.5rem",
          paddingTop: "32px",
          paddingBottom: "32px",
          paddingLeft: "20%",
          paddingRight: "20%",
        }}
      >
        <div style={{ fontSize: "1.5rem" }}>
          {this.state.lang.howToImePay.imePay}
        </div>
        <div className="hr white"></div>
        {/* <iframe width="100%" height={150} src="https://www.youtube.com/embed/tgbNymZ7vqY" frameborder="0" allow="accelerometer" allowfullscreen="allowfullscreen" /> */}
        <ol style={{ textAlign: "left", paddingLeft: "15px" }}>
          <li>{this.state.lang.howToImePay.steps[0]}</li>
          <li>
            {this.state.lang.howToImePay.steps[1][0]}{" "}
            <code>{this.state.lang.howToImePay.steps[1][1]}</code>
            {this.state.lang.howToImePay.steps[1][2]}{" "}
            <code>{this.state.lang.howToImePay.steps[1][3]}</code>
          </li>
          <li>
            {this.state.lang.howToImePay.steps[2][0]}
            <code>{this.state.lang.howToImePay.steps[2][1]}</code>
            {this.state.lang.howToImePay.steps[2][2]}
          </li>
          <li>{this.state.lang.howToImePay.steps[3]}</li>
        </ol>
        {/* <div
          className="button"
          onClick={() =>
            window.open(
              "https://esewa.com.np/#/make_payment/NP-ES-TMSINAPP/Trade%20Management%20System%20(TMS)"
            )
          }
        >
          {this.state.lang.howToEsewa.gotoEsewa}
        </div> */}
      </div>
    );
  }

  renderHowToFonePay() {
    return (
      <div
        style={{
          width: "100%",
          fontSize: "1.5rem",
          paddingTop: "32px",
          paddingBottom: "32px",
          paddingLeft: "20%",
          paddingRight: "20%",
        }}
      >
        <div style={{ fontSize: "1.5rem" }}>
          {this.state.lang.howToFonePay.fonePay}
        </div>
        <div className="hr white"></div>
        <img src="/qr_fonepay.png" alt="" style={{ width: "100%" }} />
        <ol style={{ textAlign: "left", paddingLeft: "15px" }}>
          <li>{this.state.lang.howToFonePay.steps[0]}</li>
          <li>{this.state.lang.howToFonePay.steps[1]}</li>
          <li>{this.state.lang.howToFonePay.steps[2]}</li>
        </ol>
      </div>
    );
  }

  renderHowToBankTransfer() {
    return (
      <div
        style={{
          width: "100%",
          fontSize: "1.5rem",
          paddingTop: "32px",
          paddingBottom: "32px",
          paddingLeft: "20%",
          paddingRight: "20%",
        }}
      >
        <div style={{ fontSize: "1.5rem" }}>
          {this.state.lang.howtoBankTransfer.bankTransfer}
        </div>
        <div className="hr white"></div>
        {/* <iframe width="100%" height={150} src="https://www.youtube.com/embed/rvlEOxHgA28" frameborder="0" allow="accelerometer" allowfullscreen="allowfullscreen" /> */}
        <div style={{ fontSize: "0.95rem", textAlign: "left" }}>
          <div style={{ marginTop: "8px", marginBottom: "8px" }}>
            {this.state.lang.howtoBankTransfer.paymentDetails}
          </div>
          <code>{this.state.lang.howtoBankTransfer.paymentDetailsList[0]}</code>
          <br />
          <code>{this.state.lang.howtoBankTransfer.paymentDetailsList[1]}</code>
          <br />
          <code>{this.state.lang.howtoBankTransfer.paymentDetailsList[2]}</code>
        </div>
        <ol style={{ textAlign: "left", paddingLeft: "15px" }}>
          <li>{this.state.lang.howtoBankTransfer.steps[0]}</li>
          <li>{this.state.lang.howtoBankTransfer.steps[1]}</li>
          <li>{this.state.lang.howtoBankTransfer.steps[2]}</li>
        </ol>
      </div>
    );
  }

  renderPayments() {
    return (
      <>
        <div className="openAccount-rightPanel" style={{ flex: 1 }}>
          <div>
            {this.renderTitle(this.state.lang.payments.chooseYourPayment)}
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-around",
                flexWrap: "wrap",
                alignItems: "top",
              }}
            >
              <div
                data-tip={this.state.lang.payments.tipConnectIps}
                data-for="payments"
                className="payment-container"
              >
                <PaymentItem
                  tipPlace="right"
                  tipContent={this.renderHowToConnectIps}
                  url="https://login.connectips.com/"
                  qrcode="/logo_connect_ips.png"
                  clickCallback={this.handlePaymentClick}
                />
              </div>
              <div
                data-tip={this.state.lang.payments.tipEsewa}
                data-for="payments"
                className="payment-container"
              >
                <PaymentItem
                  tipPlace="right"
                  tipContent={this.renderHowToEsewa}
                  url="https://esewa.com.np/#/make_payment/NP-ES-TMSINAPP/Trade%20Management%20System%20(TMS)"
                  qrcode="/logo_esewa.png"
                  clickCallback={this.handlePaymentClick}
                />
              </div>
              <div
                data-tip={this.state.lang.payments.tipKhalti}
                data-for="payments"
                className="payment-container"
              >
                <PaymentItem
                  tipPlace="right"
                  tipContent={this.renderHowToKhalti}
                  url="https://web.khalti.com/"
                  qrcode="/logo_khalti.png"
                  clickCallback={this.handlePaymentClick}
                />
              </div>
              <div
                data-tip={this.state.lang.payments.tipImePay}
                data-for="payments"
                className="payment-container"
              >
                <PaymentItem
                  tipPlace="right"
                  tipContent={this.renderHowToImePay}
                  url="https://www.imepay.com.np/#/"
                  qrcode="/logo_ime_pay.svg"
                  clickCallback={this.handlePaymentClick}
                />
              </div>
              <div
                data-tip={this.state.lang.payments.tipFonePay}
                data-for="payments"
                className="payment-container"
              >
                <PaymentItem
                  tipPlace="left"
                  tipContent={this.renderHowToFonePay}
                  qrcode="/qr_fonepay.png"
                  clickCallback={this.handlePaymentClick}
                />
              </div>
              <div
                data-tip={this.state.lang.payments.tipBankTransfer}
                data-for="payments"
                className="payment-container"
              >
                <PaymentItem
                  tipPlace="left"
                  tipContent={this.renderHowToBankTransfer}
                  clickCallback={this.handlePaymentClick}
                  big={true}
                  qrcode="/logo_bank.png"
                  label="Bank Deposit"
                  notes={
                    <div style={{ color: "#043984", fontWeight: 500 }}>
                      A/C Name:
                      <code style={{ padding: "6px" }}>
                        {this.state.lang.payments.bankDetails[0]}
                      </code>
                      <br />
                      A/C Number:
                      <code style={{ padding: "6px" }}>
                        {this.state.lang.payments.bankDetails[1]}
                      </code>
                      <br />
                      {this.state.lang.payments.bankDetails[2][0]}:{" "}
                      <code>{this.state.lang.payments.bankDetails[2][1]}</code>
                    </div>
                  }
                />
              </div>
              <ReactTooltip place="top" id="payments" />
            </div>
            <div style={{ fontSize: "0.95rem", marginTop: "16px" }}>
              {this.state.lang.payments.sendReceipt[0]}{" "}
              <code>{this.state.lang.payments.sendReceipt[1]}</code>{" "}
              {this.state.lang.payments.sendReceipt[2]}{" "}
              {this.renderEmail("accounts@jfsecurities.com")}{" "}
              {this.state.lang.payments.sendReceipt[3]}{" "}
              {this.renderPhone("9861413588")}
            </div>
          </div>
        </div>
        <div
          className="openAccount-leftPanel"
          style={{
            flex: this.state.paymentHelpContent ? 0.3 : 0,
            textAlign: "center",
            transitionDuration: "500ms",
            background: "#2f2f2f",
            color: "white",
          }}
        >
          {this.state.paymentHelpContent}
        </div>
      </>
    );
  }

  renderFooter() {
    return (
      <div
        style={{
          color: "white",
          fontSize: "0.85rem",
          width: "50%",
          marginLeft: "auto",
          marginRight: "auto",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <div>JF Securities Pvt. Ltd.</div>
        </div>
        <div style={{ textAlign: "right" }}>
          <a
            style={{ color: "white", textDecoration: "none" }}
            href="https://www.facebook.com/jfsecurities"
          >
            Facebook
          </a>
          <div>{this.state.lang.footer.officeTime}</div>
          <div>{this.state.lang.footer.marketTime}</div>
        </div>
      </div>
    );
  }

  renderSupportFaqs(title, faqs) {
    return (
      <div className="support-hover-tooltip">
        <ol className="support-item-faq">
          {faqs.map(function (d, idx) {
            return <li key={idx}>{d}</li>;
          })}
        </ol>
      </div>
    );
  }

  renderSupportOpenNewTradingOnlineRegistration() {
    return (
      <>
        {this.renderSupportFaqs(
          this.state.lang.supportTms.onlineRegistration.steps[0],
          this.state.lang.supportTms.onlineRegistration.steps.slice(1)
        )}
      </>
    );
  }

  renderSupportOpenNewTradingPhysically() {
    return (
      <>
        {this.renderSupportFaqs(
          this.state.lang.supportTms.newTradingPhysically.steps[0],
          this.state.lang.supportTms.newTradingPhysically.steps.slice(1)
        )}
      </>
    );
  }

  renderSupportOpenNewTradingEmail() {
    return (
      <>
        {this.renderSupportFaqs(
          this.state.lang.supportTms.newTradingEmail.steps[0],
          this.state.lang.supportTms.newTradingEmail.steps.slice(1)
        )}
      </>
    );
  }

  renderSupportUpdateKyc() {
    return (
      <>
        {this.renderSupportFaqs(
          this.state.lang.supportTms.updateKyc.steps[0],
          this.state.lang.supportTms.updateKyc.steps.slice(1)
        )}
      </>
    );
  }

  renderSupportResetPassword() {
    return (
      <>
        {this.renderSupportFaqs(
          this.state.lang.supportTms.resetPassword.steps[0],
          this.state.lang.supportTms.resetPassword.steps.slice(1)
        )}
      </>
    );
  }

  renderPaymentForPurchase() {
    return (
      <>
        {this.renderSupportFaqs(
          this.state.lang.supportTms.paymentPurchase.steps[0],
          this.state.lang.supportTms.paymentPurchase.steps.slice(1)
        )}
      </>
    );
  }

  renderSupportBuyShareAndCheckExecution() {
    return (
      <>
        {this.renderSupportFaqs(
          this.state.lang.supportTms.buyShare.how[0],
          this.state.lang.supportTms.buyShare.how.slice(1)
        )}
        {this.renderSupportFaqs(
          this.state.lang.supportTms.buyShare.check[0],
          this.state.lang.supportTms.buyShare.check.slice(1)
        )}
      </>
    );
  }

  renderSupportSellShareAndCheckExecution() {
    return (
      <>
        {this.renderSupportFaqs(
          this.state.lang.supportTms.sellShare.how[0],
          this.state.lang.supportTms.sellShare.how.slice(1)
        )}
        {this.renderSupportFaqs(
          this.state.lang.supportTms.sellShare.check[0],
          this.state.lang.supportTms.sellShare.check.slice(1)
        )}
      </>
    );
  }
  renderSupportOrderEditAndCancelOrder() {
    return (
      <>
        {this.renderSupportFaqs(
          this.state.lang.supportTms.editCancelOrder.edit[0],
          this.state.lang.supportTms.editCancelOrder.edit.slice(1)
        )}
        {this.renderSupportFaqs(
          this.state.lang.supportTms.editCancelOrder.cancel[0],
          this.state.lang.supportTms.editCancelOrder.cancel.slice(1)
        )}
      </>
    );
  }
  renderSupportLoadCollateral() {
    return (
      <>
        {this.renderSupportFaqs(
          this.state.lang.supportTms.loadCollateral.steps[0],
          this.state.lang.supportTms.loadCollateral.steps.slice(1)
        )}
      </>
    );
  }
  renderSupportRequestRefundCollateral() {
    return (
      <>
        {this.renderSupportFaqs(
          this.state.lang.supportTms.refundCollateral.steps[0],
          this.state.lang.supportTms.refundCollateral.steps.slice(1)
        )}
      </>
    );
  }

  // toggleDiv = () => {
  //   this.setState(
  //     (prevState) => ({ show: !prevState.show }),
  //     () => {
  //       // Auto scroll to center if the div is being shown
  //       if (this.state.show && this.scrollContainerRef.current) {
  //         setTimeout(() => {
  //           const container = this.scrollContainerRef.current;
  //           const scrollTop =
  //             container.scrollHeight / 2 - container.clientHeight / 2;
  //           container.scrollTo({
  //             top: scrollTop,
  //             behavior: "smooth",
  //           });
  //         }, 100); // Slight delay to ensure visibility toggle has taken effect
  //       }
  //     }
  //   );
  // };

  // renderCollapsingDiv(title, content) {
  //   return (
  //     <div>
  //       <div
  //         className="support-collapse-box"
  //         onClick={this.toggleDiv}

  //         // onClick={() => this.setState({ show: !this.state.show })}
  //       >
  //         {this.state.show ? " - " : " + "}
  //         {title}
  //       </div>
  //       {this.state.show && <div>{content}</div>}
  //     </div>
  //   );
  // }

  renderSupportTms() {
    return (
      <div className="support-item">
        <div className="support-item-title">TMS</div>

        <div className="support-item-content">
          {/* <CollapsingDiv
            marginBottom="8px"
            title={this.state.lang.supportTms.onlineRegistration.title}
          >
            {this.renderSupportOpenNewTradingOnlineRegistration()}
          </CollapsingDiv>
          <CollapsingDiv
            marginBottom="8px"
            title={this.state.lang.supportTms.newTradingPhysically.title}
          >
            {this.renderSupportOpenNewTradingPhysically()}
          </CollapsingDiv>
          <CollapsingDiv
            marginBottom="8px"
            title={this.state.lang.supportTms.newTradingEmail.title}
          >
            {this.renderSupportOpenNewTradingEmail()}
          </CollapsingDiv>
          <CollapsingDiv
            marginBottom="8px"
            title={this.state.lang.supportTms.updateKyc.title}
          >
            {this.renderSupportUpdateKyc()}
          </CollapsingDiv>
          <CollapsingDiv
            marginBottom="8px"
            title={this.state.lang.supportTms.resetPassword.title}
          >
            {this.renderSupportResetPassword()}
          </CollapsingDiv>
          <CollapsingDiv
            marginBottom="8px"
            title={this.state.lang.supportTms.paymentPurchase.title}
          >
            {this.renderPaymentForPurchase()}div>
          <div
            className="support-item-faq clickable"
            data-tip={ReactDOMServer.renderToString(
              this.renderSupportUpdateKyc()
            )}
            data-for="support-tmp"
            onClick={this.handlePageChangeOpenAccount}
          >
            {this.state.lang.supportTms.updateKyc.title}
          </
          </CollapsingDiv>
          <CollapsingDiv
            marginBottom="8px"
            title={this.state.lang.supportTms.buyShare.title}
          >
            {this.renderSupportBuyShareAndCheckExecution()}
          </CollapsingDiv>
          <CollapsingDiv
            marginBottom="8px"
            title={this.state.lang.supportTms.sellShare.title}
          >
            {this.renderSupportSellShareAndCheckExecution()}
          </CollapsingDiv>
          <CollapsingDiv
            marginBottom="8px"
            title={this.state.lang.supportTms.editCancelOrder.title}
          >
            {this.renderSupportOrderEditAndCancelOrder()}
          </CollapsingDiv>
          <CollapsingDiv
            marginBottom="8px"
            title={this.state.lang.supportTms.loadCollateral.title}
          >
            {this.renderSupportLoadCollateral()}
          </CollapsingDiv>
          <CollapsingDiv
            marginBottom="8px"
            title={this.state.lang.supportTms.refundCollateral.title}
          >
            {this.renderSupportRequestRefundCollateral()}
          </CollapsingDiv> */}
          <div
            className="support-item-faq clickable"
            data-tip={ReactDOMServer.renderToString(
              this.renderSupportOpenNewTradingOnlineRegistration()
            )}
            data-for="support-tmp"
            onClick={this.handlePageChangeOpenAccount}
          >
            {this.state.lang.supportTms.onlineRegistration.title}
          </div>
          <div
            className="support-item-faq clickable"
            data-tip={ReactDOMServer.renderToString(
              this.renderSupportOpenNewTradingPhysically()
            )}
            data-for="support-tmp"
            onClick={this.handlePageChangeOpenAccount}
          >
            {this.state.lang.supportTms.newTradingPhysically.title}
          </div>
          <div
            className="support-item-faq clickable"
            data-tip={ReactDOMServer.renderToString(
              this.renderSupportOpenNewTradingEmail()
            )}
            data-for="support-tmp"
            onClick={this.handlePageChangeOpenAccount}
          >
            {this.state.lang.supportTms.newTradingEmail.title}
          </div>
          <div
            className="support-item-faq clickable"
            data-tip={ReactDOMServer.renderToString(
              this.renderSupportUpdateKyc()
            )}
            data-for="support-tmp"
            onClick={this.handlePageChangeOpenAccount}
          >
            {this.state.lang.supportTms.updateKyc.title}
          </div>
          <div
            className="support-item-faq clickable"
            data-tip={ReactDOMServer.renderToString(
              this.renderSupportResetPassword()
            )}
            data-for="support-tmp"
            onClick={() => window.open("https://tms07.nepsetms.com.np/forgot")}
          >
            {this.state.lang.supportTms.resetPassword.title}
          </div>
          <div
            className="support-item-faq clickable"
            data-tip={ReactDOMServer.renderToString(
              this.renderPaymentForPurchase()
            )}
            data-for="support-tmp"
            onClick={this.handlePageChangePayments}
          >
            {this.state.lang.supportTms.paymentPurchase.title}
          </div>
          <div
            className="support-item-faq"
            data-tip={ReactDOMServer.renderToString(
              this.renderSupportBuyShareAndCheckExecution()
            )}
            data-for="support-tmp"
          >
            {this.state.lang.supportTms.buyShare.title}
          </div>
          <div
            className="support-item-faq"
            data-tip={ReactDOMServer.renderToString(
              this.renderSupportSellShareAndCheckExecution()
            )}
            data-for="support-tmp"
          >
            {this.state.lang.supportTms.sellShare.title}
          </div>
          <div
            className="support-item-faq"
            data-tip={ReactDOMServer.renderToString(
              this.renderSupportOrderEditAndCancelOrder()
            )}
            data-for="support-tmp"
          >
            {this.state.lang.supportTms.editCancelOrder.title}
          </div>
          <div
            className="support-item-faq"
            data-tip={ReactDOMServer.renderToString(
              this.renderSupportLoadCollateral()
            )}
            data-for="support-tmp"
          >
            {this.state.lang.supportTms.loadCollateral.title}
          </div>
          <div
            className="support-item-faq"
            data-tip={ReactDOMServer.renderToString(
              this.renderSupportRequestRefundCollateral()
            )}
            data-for="support-tmp"
          >
            {this.state.lang.supportTms.refundCollateral.title}
          </div>
        </div>
        <ReactTooltip place="right" id="support-tmp" html={true} />
      </div>
    );
  }

  renderSupportMeroShare() {
    return <div className="support-item"></div>;
  }

  handleSupportOptionClick = (option) => {
    this.setState({ selectedSupportOption: option });
  };

  renderSupportOptions(supportOption) {
    return (
      <div className="language-menu">
        <div
          className={`language-menu-item ${
            supportOption === 1 && "language-menu-item-active"
          }`}
          onClick={() => this.handleSupportOptionClick(1)}
        >
          TMS
        </div>

        <div
          className={`language-menu-item ${
            supportOption === 2 && "language-menu-item-active"
          }`}
          onClick={() => this.handleSupportOptionClick(2)}
        >
          Mero Share
        </div>
      </div>
    );
  }

  renderSupport() {
    const { selectedSupportOption } = this.state;
    return (
      <div className="support">
        {this.renderTitle(this.state.lang.supportTms.title, true)}
        {/* {this.renderSupportOptions(selectedSupportOption)} */}
        <div className="support-items" ref={this.scrollContainerRef}>
          {selectedSupportOption === 1 ? this.renderSupportTms() : null}
          {selectedSupportOption === 1 ? this.renderSupportMeroShare() : null}
        </div>
      </div>
    );
  }

  renderDepartmentContacts(title, email, contacts) {
    const contactItems = [];
    for (let i in contacts) {
      contactItems.push(
        <li className="contact-item" key={i}>
          {contacts[i].name} -{" "}
          <code>{this.renderPhone(contacts[i].number)}</code>
        </li>
      );
    }
    return (
      <div className="department-item">
        <div className="department-item-title">
          <div>{title}</div>
          {email && (
            <code className="department-item-email">
              {this.renderEmail(
                email,
                undefined,
                "Need assistance, Please contact me!",
                "Hi,%0A%0AI required some assistance. Please call me on my number xxxxxxxxxx "
              )}
            </code>
          )}
        </div>
        <ol className="contacts">{contactItems}</ol>
      </div>
    );
  }

  mouseEnterScrollerDiv() {
    // todo unbind wheel
    this.unBindMouseWheel();
  }
  mouseLeaveScrollerDiv() {
    // todo bind wheel
    this.bindMouseWheel();
  }

  redirectToVSupport() {
    let url = decodeURIComponent(
      escape(window.atob(this.state.vSupportLink))
    ).split("::::")[1];
    window.location.href = url;
  }

  KathmanduBranch() {
    return (
      <div style={{ width: "100%" }}>
        <div className="contacts-section">
          <div style={{ width: "100%", marginLeft: "15%", marginRight: "15%" }}>
            <div
              className="departments"
              style={{ maxHeight: "67vh", overflowY: "auto" }}
              onMouseEnter={this.mouseEnterScrollerDiv}
              onMouseLeave={this.mouseLeaveScrollerDiv}
            >
              {this.renderDepartmentContacts(
                this.state.lang.contactUs.trading,
                undefined,
                [
                  { name: "Parbati Karki", number: "9847771057" },
                  { name: "Rasila Sharma", number: "9847770434" },
                ]
              )}
              {this.renderDepartmentContacts(
                this.state.lang.contactUs.accounts,
                "accounts@jfsecurities.com",
                [
                  { name: "Purnima Ranjitkar - Payin", number: "9861413588" },
                  { name: "Deepa Shahi - Payin", number: "9847770533" },
                  { name: "Sajana Ranjit - Payout", number: "9847774464" },
                  { name: "Nirmala Magar", number: "9847773155" },
                  { name: "Dipesh Maharjan", number: "9847773840" },
                ]
              )}
              {this.renderDepartmentContacts(
                this.state.lang.contactUs.operations,
                "operations@jfsecurities.com",
                [
                  { name: "Ajay Das Joshi", number: "9843697582" },
                  { name: "Yasodha Budhathoki", number: "9847771097" },
                  { name: "Pradip Nepali", number: "9847773626" },
                ]
              )}
              {this.renderDepartmentContacts(
                this.state.lang.contactUs.customerCare,
                "support@jfsecurities.com",
                [
                  { name: "Muna Moktan", number: "9847773822" },
                  { name: "Dipika Shahi", number: "9847773880" },
                ]
              )}
              <div className="telNumbers" style={{ display: "flex" }}>
                {this.state.lang.contactUs.tel}:{" "}
                <code>
                  {this.renderPhone("01-5356099")},{" "}
                  {this.renderPhone("01-5348202")},{" "}
                  {this.renderPhone("01-5312072")}
                </code>
                <br />
                <code style={{ flex: 1, textAlign: "end" }}>
                  {this.renderEmail(
                    "info@jfsecurities.com",
                    undefined,
                    "Need help, Please contact me.",
                    "Hi, I need some assistance, please contact me."
                  )}
                </code>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  DamakBranch() {
    return (
      <div style={{ width: "100%" }}>
        <div className="contacts-section" style={{ display: "flex" }}>
          <div style={{ width: "100%", marginLeft: "15%", marginRight: "15%" }}>
            <div
              className="departments"
              style={{ maxHeight: "67vh", overflowY: "auto" }}
              onMouseEnter={this.mouseEnterScrollerDiv}
              onMouseLeave={this.mouseLeaveScrollerDiv}
            >
              {this.renderDepartmentContacts(
                this.state.lang.contactUs.bm,
                undefined,
                [{ name: "Bharat Neupane", number: "9748277123" }]
              )}
              {this.renderDepartmentContacts(
                this.state.lang.contactUs.customerCare,
                "support@jfsecurities.com",
                [{ name: "Umesh Kafle", number: "9748277124" }]
              )}

              {this.renderDepartmentContacts(
                this.state.lang.contactUs.operations,
                "operations@jfsecurities.com",
                [
                  { name: "Roshan Kafle", number: "9748277125" },
                  { name: "Saugat Gautam", number: "9748277126" },
                ]
              )}

              <div className="telNumbers" style={{ display: "flex" }}>
                {this.state.lang.contactUs.tel}:{" "}
                <code>
                  {this.renderPhone("023-570540")},{" "}
                  {this.renderPhone("023-578540")}
                </code>
                <br />
                <code style={{ flex: 1, textAlign: "end" }}>
                  {this.renderEmail(
                    "info@jfsecurities.com",
                    undefined,
                    "Need help, Please contact me.",
                    "Hi, I need some assistance, please contact me."
                  )}
                </code>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  DangBranch() {
    return (
      <div style={{ width: "100%" }}>
        <div className="contacts-section" style={{ display: "flex" }}>
          <div style={{ width: "100%", marginLeft: "15%", marginRight: "15%" }}>
            <div
              className="departments"
              style={{ maxHeight: "67vh", overflowY: "auto" }}
              onMouseEnter={this.mouseEnterScrollerDiv}
              onMouseLeave={this.mouseLeaveScrollerDiv}
            >
              {this.renderDepartmentContacts(
                this.state.lang.contactUs.bm,
                undefined,
                [{ name: "Sabin Raj Dangi", number: "9748277113" }]
              )}
              {this.renderDepartmentContacts(
                this.state.lang.contactUs.accounts,
                "accounts@jfsecurities.com",
                [{ name: "Deepika Basnet", number: "9748277114" }]
              )}
              {this.renderDepartmentContacts(
                this.state.lang.contactUs.customerCare,
                "support@jfsecurities.com",
                [{ name: "Nirmala Oli", number: "9748277115" }]
              )}

              <div className="telNumbers" style={{ display: "flex" }}>
                {this.state.lang.contactUs.tel}:{" "}
                <code>
                  {this.renderPhone("082-590172")},{" "}
                  {this.renderPhone("082-590173")}
                </code>
                <br />
                <code style={{ flex: 1, textAlign: "end" }}>
                  {this.renderEmail(
                    "info@jfsecurities.com",
                    undefined,
                    "Need help, Please contact me.",
                    "Hi, I need some assistance, please contact me."
                  )}
                </code>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleBranchButtonClick = (branch) => {
    this.setState({ selectedBranch: branch });
  };

  renderContactUsSection() {
    const { selectedBranch } = this.state;

    return (
      <div style={{ display: "flex" }}>
        <div>
          {selectedBranch === 1 ? <KathmanduMap /> : null}
          {selectedBranch === 2 ? <DamakMap /> : null}
          {selectedBranch === 3 ? <DangMap /> : null}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            height: "100vh",
          }}
        >
          {this.renderTitle(this.state.lang.contactUs.title)}

          <div
            style={{
              cursor: "pointer",
              textDecoration: "underline",
              marginBottom: "10px",
              fontSize: "1.5rem",
            }}
            onClick={this.redirectToVSupport}
          >
            {this.state.lang.contactUs.joinViber}
          </div>
          <div style={{ fontSize: "1.3rem", marginBottom: "5px" }}>
            {this.state.lang.contactUs.location}
          </div>
          <div className="language-menu">
            <div
              className={`language-menu-item ${
                selectedBranch === 1 && "language-menu-item-active"
              }`}
              onClick={() => this.handleBranchButtonClick(1)}
            >
              {this.state.lang.contactUs.kathmanduBranch}
            </div>

            <div
              className={`language-menu-item ${
                selectedBranch === 2 && "language-menu-item-active"
              }`}
              onClick={() => this.handleBranchButtonClick(2)}
            >
              {this.state.lang.contactUs.damakBranch}
            </div>
            <div
              className={`language-menu-item ${
                selectedBranch === 3 && "language-menu-item-active"
              }`}
              onClick={() => this.handleBranchButtonClick(3)}
            >
              {this.state.lang.contactUs.dangBranch}
            </div>
          </div>
          {selectedBranch === 1 ? this.KathmanduBranch() : null}
          {selectedBranch === 2 ? this.DamakBranch() : null}
          {selectedBranch === 3 ? this.DangBranch() : null}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div
        class="fullpage"
        style={{ height: "100vh", overflowY: "hidden", overflowX: "hidden" }}
      >
        <div
          ref={this.pageMain}
          className="section"
          style={{ height: "100vh", position: "relative" }}
        >
          <div id="chart-bg" className="chart-bg"></div>
          <div className="chart-bg-overlay"></div>
          {this.renderMainPage()}
          <div className="main-hr"></div>

          {this.renderMainPageFooter()}
        </div>
        <div
          ref={this.pageOurServices}
          className="section"
          style={{
            background: "white",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {this.renderOurServices()}
        </div>
        <div
          ref={this.pageJoinUs}
          className="section"
          style={{ background: "white", height: "100vh", display: "flex" }}
        >
          {this.renderOpenAccount()}
        </div>
        <div
          ref={this.pagePayments}
          className="section"
          style={{
            background: "white",
            height: "100vh",
            display: "flex",
          }}
        >
          {this.renderPayments()}
        </div>
        <div
          ref={this.pageSupport}
          className="section"
          style={{
            background: "#08644c",
            paddingTop: "20px",
            paddingBottom: "20px",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {this.renderSupport()}
        </div>
        <div
          ref={this.pageContactUsSection}
          className="section"
          style={{ background: "white", height: "100vh" }}
        >
          {this.renderContactUsSection()}
        </div>
        <div
          ref={this.pageFooter}
          className="section"
          style={{
            background: "#08644c",
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        >
          {this.renderFooter()}
        </div>
      </div>
    );
  }
}

export default App;

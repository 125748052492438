import React from "react";

class HorizontalTalContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: -1,
    };
  }

  componentDidMount() {}

  render() {
    const titles = [];
    const contents = [];
    for (let i = 0; i < this.props.tabs.length; i++) {
      titles.push(
        <div className={`hor-tab-title ${this.state.tab == i ? "active" : ""}`} onClick={() => this.setState({ tab: i })}>
          {this.props.tabs[i].title}
        </div>
      );
      contents.push(this.props.tabs[i].content);
    }

    if (!titles || !contents) return <></>;
    return (
      <div className="hor-tab-container">
        <div className="hor-tab-titlebar">{titles}</div>
        {this.state.tab >= 0 && <div className="hor-content">{contents[this.state.tab]}</div>}
      </div>
    );
  }
}

export default HorizontalTalContainer;

// import "./AppMobile.css";
import React from "react";

import HorizontalTalContainer from "./HorizontalTabContainerMobile";
import JfMap from "./JfMapMobile";
import CollapsingDiv from "./CollpasingDiv";

import Strings from "../Strings";
import DamakMapMobile from "./DamakMapMobile";
import DangMapMobile from "./DangMapMobile";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.pageMain = React.createRef();
    this.pageOurServices = React.createRef();
    this.pageJoinUs = React.createRef();
    this.pagePayments = React.createRef();
    this.pageSupport = React.createRef();
    this.pageContactUsSection = React.createRef();
    this.pageFooter = React.createRef();

    this.state = {
      lang: Strings.eng,
      selectedBranch: 1,

      navpanelCss: "navpanel-enter",
      dotpanelCss: "navpanel-exit",
      showRegistration: true,
      jfCss: "",
      content: undefined,
      contentCss: "content-enter",
      sidePanel: undefined,
      sidePanelCss: "sidepanel-enter",
      activeId: undefined,

      paymentsWidth: "50%",

      scrollIndex: 0,
      pages: [
        this.pageMain,
        this.pageOurServices,
        this.pageJoinUs,
        this.pagePayments,
        this.pageSupport,
        this.pageContactUsSection,
        this.pageFooter,
      ],
      vSupportLink:
        "c29tZV9zYWx0Ojo6Omh0dHBzOi8vaW52aXRlLnZpYmVyLmNvbS8/ZzI9QVFBYktjdXJVbVBKYUUyNUNFMEtrcSUyQmpvZzRTTmxFN3ZaRFBnRmlDUUNwcXlvYXRjMWxxeXZsSzdHRlpVZWNU",
    };
    window.bg_scroll = true;

    this.frame_width = "180";
    this.frame_height = "100";

    this.changeTab = this.changeTab.bind(this);
    this.renderPayments = this.renderPayments.bind(this);
    this.renderContactUsSection = this.renderContactUsSection.bind(this);

    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.scrollPage = this.scrollPage.bind(this);
    this.handlePaymentClick = this.handlePaymentClick.bind(this);

    this.handlePageChangeOpenAccount =
      this.handlePageChangeOpenAccount.bind(this);
    this.handlePageChangePayments = this.handlePageChangePayments.bind(this);

    this.renderLanguageToggle = this.renderLanguageToggle.bind(this);

    this.renderHowToConnectIps = this.renderHowToConnectIps.bind(this);
    this.renderHowToEsewa = this.renderHowToEsewa.bind(this);
    this.renderHowToKhalti = this.renderHowToKhalti.bind(this);
    this.renderHowToImePay = this.renderHowToImePay.bind(this);
    this.renderHowToFonePay = this.renderHowToFonePay.bind(this);
    this.renderHowToBankTransfer = this.renderHowToBankTransfer.bind(this);

    this.redirectToVSupport = this.redirectToVSupport.bind(this);
  }

  componentDidMount() {}

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  scrollPage(index) {
    window.bg_scroll = index == 0;
    this.setState(
      {
        navpanelCss: index == 0 ? "navpanel-enter" : "navpanel-exit",
        dotpanelCss: index == 0 ? "navpanel-exit" : "navpanel-enter",
      },
      () => {
        this.state.pages[index].current.scrollIntoView({ behavior: "smooth" });
        setTimeout(
          () => this.setState({ scrollIndex: index }),
          parseInt(
            getComputedStyle(document.documentElement).getPropertyValue(
              "--anim-duration-long"
            )
          )
        );
      }
    );
  }

  handleScroll(event) {
    event.preventDefault();
    event.stopPropagation();
    const currentTime = new Date().getTime();
    if (!this.state.lastScroll || currentTime - this.state.lastScroll > 300) {
      if (event.deltaY < 0)
        this.state.scrollIndex = Math.max(0, this.state.scrollIndex - 1);
      if (event.deltaY > 0)
        this.state.scrollIndex = Math.min(
          this.state.pages.length - 1,
          this.state.scrollIndex + 1
        );
      this.scrollPage(this.state.scrollIndex);
    }
    this.state.lastScroll = currentTime;
  }

  changeTab = (id, renderMethod, sidePanelMethod) => {
    if (id == this.state.activeId) return;
    const navHeight = getComputedStyle(
      document.documentElement
    ).getPropertyValue("--nav-bar-height-collapsed");
    document.documentElement.style.setProperty("--nav-bar-height", navHeight);
    document.documentElement.style.setProperty("--nav-tools-align", "16px");
    document.documentElement.style.setProperty("--nav-text-color", "#08644c");
    document.documentElement.style.setProperty("--nav-bg-color", "#08644c");
    document.documentElement.style.setProperty("--nav-bar-z-index", "1000");
    const animationDuration = this.state.content
      ? "--anim-duration"
      : "--anim-duration-long";
    this.setState(
      {
        showRegistration: false,
        jfCss: "collapse",
        contentCss: "content-exit",
        sidePanelCss: "sidepanel-exit",
        activeId: id,
      },
      () =>
        setTimeout(
          () =>
            this.setState({
              content: renderMethod(),
              contentCss: "content-enter",
              sidePanel: sidePanelMethod ? sidePanelMethod() : undefined,
              sidePanelCss: "sidepanel-enter",
            }),
          parseInt(
            getComputedStyle(document.documentElement).getPropertyValue(
              animationDuration
            )
          )
        )
    );
  };

  renderTitle(title, isWhite) {
    return (
      <div className={`section-title ${isWhite ? "white" : ""}`}>
        {title}
        <div className={`hr ${isWhite ? "white" : ""}`}></div>
      </div>
    );
  }

  renderImgButton(icon, link, label, isInvert) {
    return (
      <div
        className="img-button"
        onClick={() => window.open(link)}
        style={{ marginLeft: "12px", marginRight: "12px" }}
      >
        <img className={`button-icon ${isInvert ? "invert" : ""}`} src={icon} />
        {label}
      </div>
    );
  }

  renderEmail(email, className, subject, body, isWhite) {
    let mailto = `mailto:${email}`;
    let params = [];
    if (subject) {
      params.push(`subject=${subject}`);
    }
    if (body) {
      params.push(`body=${body}`);
    }
    if (params.length > 0) {
      mailto = `${mailto}?${params.join("&")}`;
    }
    return (
      <a href={mailto} className={`${className} ${isWhite ? "white" : ""}`}>
        {email}
      </a>
    );
  }
  renderPhone(phoneNumber, className) {
    return (
      <a href={`tel:${phoneNumber}`} className={className}>
        {phoneNumber}
      </a>
    );
  }

  renderContactUs() {
    return (
      <div className="contactUs contact-enter">
        <div
          className="phoneNumbers"
          style={{ display: "flex", flexDirection: "column" }}
        >
          {this.renderPhone("01-4256099", "contact")}
          {this.renderPhone("01-4248202", "contact")}
          {this.renderPhone("01-4212072", "contact")}
          {this.renderEmail("info@jfsecurities.com", "contact")}
        </div>
      </div>
    );
  }

  renderLanguageToggle() {
    if (this.state.lang == Strings.eng) {
      return (
        <div
          className="nav"
          onClick={() => this.setState({ lang: Strings.nep })}
        >
          नेपाली
        </div>
      );
    } else {
      return (
        <div
          className="nav"
          onClick={() => this.setState({ lang: Strings.eng })}
        >
          English
        </div>
      );
    }
  }

  renderJfsRegistration() {
    if (!this.state.showRegistration) return <></>;
    return (
      <div className={`jfsecurities-registration`}>
        {this.state.lang.jfRegistration[0]}
        <br />
        {this.state.lang.jfRegistration[1]}
      </div>
    );
  }

  renderJfsLogo() {
    return (
      <div>
        <div className={`jfsecurities`}>
          <img className="logo" src="logo.png" />
          {this.state.lang.jfLogo[0]}
        </div>
        {this.renderJfsRegistration()}
      </div>
    );
  }

  handlePageChange(idx) {
    this.scrollPage(idx);
  }

  handlePageChangeOpenAccount() {
    this.handlePageChange(2);
  }

  handlePageChangePayments() {
    this.handlePageChange(3);
  }

  renderNavOptions() {
    return (
      <>
        <div style={{ marginTop: "10vh" }}>
          <h3
            className="nav"
            onClick={() => window.open("https://navyaadvisors.com/broker-login?id=26307642-e153-4b9d-bf36-29557a5be78b")}
          >
            {this.state.lang.navOptions.loginZipline}
          </h3>
          <h3
            className="nav"
            onClick={() => window.open("https://tms07.nepsetms.com.np/")}
          >
            {this.state.lang.navOptions.loginTms}
          </h3>
          <h3
            className="nav"
            onClick={() => window.open("https://meroshare.cdsc.com.np/#/login")}
          >
            {this.state.lang.navOptions.loginMeroShare}
          </h3>
          <h3
            className="nav nav-highlighted"
            onClick={this.handlePageChangeOpenAccount}
          >
            {this.state.lang.navOptions.openAccount}
          </h3>
          <h3 className="nav" onClick={this.handlePageChangePayments}>
            {this.state.lang.navOptions.makePayment}
          </h3>
          <h3 className="nav" onClick={() => this.handlePageChange(4)}>
            {this.state.lang.navOptions.faqs}
          </h3>
          <h3 className="nav" onClick={() => this.handlePageChange(5)}>
            {this.state.lang.navOptions.contactUs}
          </h3>
        </div>
      </>
    );
  }

  renderMainPage() {
    return (
      <div className="nav-bar" style={{ position: "relative" }}>
        {this.renderJfsLogo()}
        {this.renderNavOptions()}
        {this.renderLanguageToggle()}
      </div>
    );
  }

  renderOurServicesItem(icon, title, caption) {
    return (
      <div className="ourservices-item">
        <div>
          <img className="ourservices-item-icon" src={icon} />
        </div>
        <div className="ourservices-item-title">{title}</div>
        <div className="ourservices-item-caption">{caption}</div>
      </div>
    );
  }

  renderOurServices() {
    return (
      <>
        {this.renderTitle(this.state.lang.ourServices.ourServices)}
        <div>
          {this.renderOurServicesItem(
            "/our_services_brokering.png",
            this.state.lang.ourServices.brokeringService,
            this.state.lang.ourServices.brokeringServiceCaption
          )}
          {this.renderOurServicesItem(
            "/our_services_dp.png",
            this.state.lang.ourServices.dpService,
            this.state.lang.ourServices.dpServiceCaption
          )}
          {this.renderOurServicesItem(
            "/our_services_solutions.png",
            this.state.lang.ourServices.marketSolutions,
            this.state.lang.ourServices.marketSolutionsCaption
          )}
        </div>
      </>
    );
  }

  renderOnlineAccount() {
    return (
      <div style={{ marginLeft: "auto", marginRight: "auto" }}>
        {this.renderImgButton(
          "/logo_tms.png",
          "https://tms07.nepsetms.com.np/client-registration",
          this.state.lang.joinUs.openAccount
        )}
      </div>
    );
  }

  renderOpenDmatAccount() {
    return (
      <>
        {this.renderImgButton(
          "/download_icon.png",
          "/downloads/dmat_individual_account.pdf",
          this.state.lang.joinUs.openDmat.individualDmatForm,
          true
        )}
        {this.renderImgButton(
          "/download_icon.png",
          "/downloads/dmat_meroshare.pdf",
          this.state.lang.joinUs.openDmat.meroshareForm,
          true
        )}
        <div className="flex-break"></div>
        <div style={{ fontSize: "0.95rem", marginTop: "12px" }}>
          {this.state.lang.joinUs.openDmat.mailDocumentsCaption[0]}{" "}
          {this.renderEmail(
            "operations@jfsecurities.com",
            undefined,
            "Open New Dmat Account",
            "Hi,%0A%0AI want to open a new Dmat account. Please find the KYC and Meroshare forms attached to this mail.",
            true
          )}{" "}
          {this.state.lang.joinUs.offlineAccount.mailDocumentsCaption[1]}
        </div>
      </>
    );
  }

  renderOfflineAccount() {
    return (
      <>
        {this.renderImgButton(
          "/download_icon.png",
          "/downloads/kyc_individual.pdf",
          this.state.lang.joinUs.offlineAccount.individualKycForm,
          true
        )}
        {this.renderImgButton(
          "/download_icon.png",
          "/downloads/kyc_institution.pdf",
          this.state.lang.joinUs.offlineAccount.institutionKycForm,
          true
        )}
        <div className="flex-break"></div>
        <div style={{ fontSize: "0.95rem", marginTop: "12px" }}>
          {this.state.lang.joinUs.offlineAccount.mailDocumentsCaption[0]}{" "}
          {this.renderEmail(
            "operations@jfsecurities.com",
            undefined,
            "Open New Account",
            "Hi,%0A%0AI want to open a new trading account. Please find the KYC form attached to this mail.",
            true
          )}{" "}
          {this.state.lang.joinUs.offlineAccount.mailDocumentsCaption[1]}
        </div>
      </>
    );
  }
  renderUpdateAccount() {
    return (
      <>
        {this.renderImgButton(
          "/download_icon.png",
          "/downloads/kyc_individual.pdf",
          this.state.lang.joinUs.updateAccount.individualKycForm,
          true
        )}
        {this.renderImgButton(
          "/download_icon.png",
          "/downloads/kyc_institution.pdf",
          this.state.lang.joinUs.updateAccount.institutionKycForm,
          true
        )}
        <div className="flex-break"></div>
        <div style={{ fontSize: "0.95rem", marginTop: "12px" }}>
          {this.state.lang.joinUs.updateAccount.mailDocumentsCaption[0]}{" "}
          {this.renderEmail(
            "operations@jfsecurities.com",
            undefined,
            "KYC UPDATE",
            "Hi,%0A%0AI want to update my old account. My client id is xxxxxxxxxx. Please find the KYC form attachd to this mail.",
            true
          )}{" "}
          {this.state.lang.joinUs.updateAccount.mailDocumentsCaption[1]}{" "}
          <b>{this.state.lang.joinUs.updateAccount.mailDocumentsCaption[2]}</b>{" "}
          {this.state.lang.joinUs.updateAccount.mailDocumentsCaption[3]}
        </div>
      </>
    );
  }

  renderRequiredDocuments() {
    return (
      <div>
        <h4 className="openAccount-requiredDocs-title">
          {this.state.lang.joinUs.requiredDocs.requiredDocuments}
        </h4>
        <ol>
          <li>{this.state.lang.joinUs.requiredDocs.adultList[0]}</li>
          <li>{this.state.lang.joinUs.requiredDocs.adultList[1]}</li>
          <li>{this.state.lang.joinUs.requiredDocs.adultList[2]}</li>
          <li>{this.state.lang.joinUs.requiredDocs.adultList[3]}</li>
          <li>{this.state.lang.joinUs.requiredDocs.adultList[4]}</li>
          <li>{this.state.lang.joinUs.requiredDocs.adultList[5]}</li>
          <li>{this.state.lang.joinUs.requiredDocs.adultList[6]}</li>
        </ol>
        <h4 className="openAccount-requiredDocs-title">
          {this.state.lang.joinUs.requiredDocs.requriedMinorDocuments}
        </h4>
        <ol>
          <li>{this.state.lang.joinUs.requiredDocs.minorList[0]}</li>
          <li>{this.state.lang.joinUs.requiredDocs.minorList[1]}</li>
          <li>{this.state.lang.joinUs.requiredDocs.minorList[2]}</li>
        </ol>
      </div>
    );
  }
  renderOpenAccount() {
    return (
      <>
        {this.renderTitle(this.state.lang.joinUs.joinUs, true)}
        <div
          className="button"
          style={{
            marginLeft: "18%",
            marginRight: "18%",
            background: "#4e90bf",
          }}
          onClick={() =>
            window.open("https://tms07.nepsetms.com.np/client-registration")
          }
        >
          {this.state.lang.joinUs.openAccountOnline}
        </div>
        <div style={{ paddingLeft: "10%", paddingRight: "10%" }}>
          <HorizontalTalContainer
            tabs={[
              {
                title: this.state.lang.joinUs.tabs.openDmat,
                content: this.renderOpenDmatAccount(),
              },
            ]}
          />
          <HorizontalTalContainer
            tabs={[
              {
                title: this.state.lang.joinUs.tabs.downloadForm,
                content: this.renderOfflineAccount(),
              },
            ]}
          />
          <HorizontalTalContainer
            tabs={[
              {
                title: this.state.lang.joinUs.tabs.updateKyc,
                content: this.renderUpdateAccount(),
              },
            ]}
          />
          {this.renderRequiredDocuments()}
        </div>
      </>
    );
  }

  handlePaymentClick(paymentHelpContent) {
    this.setState({ paymentHelpContent });
  }

  renderHowToConnectIps() {
    return (
      <div style={{ fontSize: "1.5rem" }}>
        <iframe
          width="100%"
          height={150}
          src="https://www.youtube.com/embed/rvlEOxHgA28"
          frameborder="0"
          allow="accelerometer"
          allowfullscreen="allowfullscreen"
        />
        <ol style={{ textAlign: "left", paddingLeft: "15px" }}>
          <li>{this.state.lang.howToConnectIps.steps[0]}</li>
          <li>{this.state.lang.howToConnectIps.steps[1]}</li>
          <li>
            {this.state.lang.howToConnectIps.steps[2][0]}{" "}
            <code>{this.state.lang.howToConnectIps.steps[2][1]}</code>
          </li>
          <li>{this.state.lang.howToConnectIps.steps[3]}</li>
          <li>{this.state.lang.howToConnectIps.steps[4]}</li>
          <li>
            {this.state.lang.howToConnectIps.steps[5][0]}{" "}
            <code>{this.state.lang.howToConnectIps.steps[5][1]}</code>
          </li>
          <li>{this.state.lang.howToConnectIps.steps[6]}</li>
        </ol>
        <div
          className="button"
          onClick={() => window.open("https://login.connectips.com/")}
        >
          {this.state.lang.howToConnectIps.gotoConnectIps}
        </div>
      </div>
    );
  }

  renderHowToEsewa() {
    return (
      <div style={{ fontSize: "1.5rem" }}>
        {/* <iframe width="100%" height={150} src="https://www.youtube.com/embed/tgbNymZ7vqY" frameborder="0" allow="accelerometer" allowfullscreen="allowfullscreen" /> */}
        <ol style={{ textAlign: "left", paddingLeft: "15px" }}>
          <li>{this.state.lang.howToEsewa.steps[0]}</li>
          <li>
            {this.state.lang.howToEsewa.steps[1][0]}{" "}
            <code>{this.state.lang.howToEsewa.steps[1][1]}</code>{" "}
            {this.state.lang.howToEsewa.steps[1][2]}{" "}
            <code>{this.state.lang.howToEsewa.steps[1][3]}</code>
          </li>
          <li>{this.state.lang.howToEsewa.steps[2]}</li>
          <li>{this.state.lang.howToEsewa.steps[3]}</li>
        </ol>
        <div
          className="button"
          onClick={() =>
            window.open(
              "https://esewa.com.np/#/make_payment/NP-ES-TMSINAPP/Trade%20Management%20System%20(TMS)"
            )
          }
        >
          {this.state.lang.howToEsewa.gotoEsewa}
        </div>
      </div>
    );
  }

  renderHowToKhalti() {
    return (
      <div style={{ fontSize: "1.5rem" }}>
        {/* <iframe width="100%" height={150} src="https://www.youtube.com/embed/tgbNymZ7vqY" frameborder="0" allow="accelerometer" allowfullscreen="allowfullscreen" /> */}
        <ol style={{ textAlign: "left", paddingLeft: "15px" }}>
          <li>{this.state.lang.howToKhalti.steps[0]}</li>
          <li>
            {this.state.lang.howToKhalti.steps[1][0]}{" "}
            <code>{this.state.lang.howToKhalti.steps[1][1]}</code>{" "}
            {this.state.lang.howToKhalti.steps[1][2]}{" "}
            <code>{this.state.lang.howToKhalti.steps[1][3]}</code>
          </li>
          <li>{this.state.lang.howToKhalti.steps[2]}</li>
          <li>{this.state.lang.howToKhalti.steps[3]}</li>
        </ol>
        <div
          className="button"
          onClick={() => window.open("https://web.khalti.com/")}
        >
          {this.state.lang.howToKhalti.gotoKhalti}
        </div>
      </div>
    );
  }

  renderHowToImePay() {
    return (
      <div style={{ fontSize: "1.5rem" }}>
        {/* <iframe width="100%" height={150} src="https://www.youtube.com/embed/tgbNymZ7vqY" frameborder="0" allow="accelerometer" allowfullscreen="allowfullscreen" /> */}
        <ol style={{ textAlign: "left", paddingLeft: "15px" }}>
          <li>{this.state.lang.howToImePay.steps[0]}</li>
          <li>
            {this.state.lang.howToImePay.steps[1][0]}{" "}
            <code>{this.state.lang.howToImePay.steps[1][1]}</code>{" "}
            {this.state.lang.howToImePay.steps[1][2]}{" "}
            <code>{this.state.lang.howToImePay.steps[1][3]}</code>
          </li>
          <li>
            {this.state.lang.howToImePay.steps[2][0]}
            <code>{this.state.lang.howToImePay.steps[2][1]}</code>
            {this.state.lang.howToImePay.steps[2][2]}
          </li>
          <li>{this.state.lang.howToImePay.steps[3]}</li>
        </ol>
        {/* <div className="button" onClick={() => window.open("https://web.khalti.com/#/bazaar/yfFvJw4GQZYxY3nJDEyzBc/product")}>
          {this.state.lang.howToKhalti.gotoKhalti}
        </div> */}
      </div>
    );
  }
  renderHowToFonePay() {
    return (
      <div style={{ fontSize: "1.5rem" }}>
        <ol style={{ textAlign: "left", paddingLeft: "15px" }}>
          <li>{this.state.lang.howToFonePay.steps[0]}</li>
          <li>{this.state.lang.howToFonePay.steps[1]}</li>
          <li>{this.state.lang.howToFonePay.steps[2]}</li>
        </ol>
      </div>
    );
  }

  renderHowToBankTransfer() {
    return (
      <div style={{ fontSize: "1.5rem" }}>
        {/* <iframe width="100%" height={150} src="https://www.youtube.com/embed/rvlEOxHgA28" frameborder="0" allow="accelerometer" allowfullscreen="allowfullscreen" /> */}
        <div style={{ fontSize: "0.95rem", textAlign: "left" }}>
          <div style={{ marginTop: "8px", marginBottom: "8px" }}>
            {this.state.lang.howtoBankTransfer.paymentDetails}
          </div>
          <code>{this.state.lang.howtoBankTransfer.paymentDetailsList[0]}</code>
          <br />
          <code>{this.state.lang.howtoBankTransfer.paymentDetailsList[1]}</code>
          <br />
          <code>{this.state.lang.howtoBankTransfer.paymentDetailsList[2]}</code>
        </div>
        <ol style={{ textAlign: "left", paddingLeft: "15px" }}>
          <li>{this.state.lang.howtoBankTransfer.steps[0]}</li>
          <li>{this.state.lang.howtoBankTransfer.steps[1]}</li>
          <li>{this.state.lang.howtoBankTransfer.steps[2]}</li>
        </ol>
      </div>
    );
  }

  renderPayments() {
    return (
      <>
        <div style={{ marginLeft: "10%", marginRight: "10%" }}>
          {this.renderTitle("Payments")}
          <div>
            {this.state.lang.payments.sendReceipt[0]}{" "}
            <code>{this.state.lang.payments.sendReceipt[1]}</code>{" "}
            {this.state.lang.payments.sendReceipt[2]}{" "}
            {this.renderEmail("accounts@jfsecurities.com")}{" "}
            {this.state.lang.payments.sendReceipt[3]}{" "}
            {this.renderPhone("9861413588")}
          </div>
          <CollapsingDiv
            title={<img className="qr-code" src={"/logo_connect_ips.png"} />}
            textAlign="center"
          >
            {this.renderHowToConnectIps()}
          </CollapsingDiv>
          <CollapsingDiv
            title={<img className="qr-code" src={"/logo_esewa.png"} />}
            textAlign="center"
          >
            {this.renderHowToEsewa()}
          </CollapsingDiv>
          <CollapsingDiv
            title={<img className="qr-code" src={"/logo_khalti.png"} />}
            textAlign="center"
          >
            {this.renderHowToKhalti()}
          </CollapsingDiv>
          <CollapsingDiv
            title={<img className="qr-code" src={"/logo_ime_pay.svg"} />}
            textAlign="center"
          >
            {this.renderHowToImePay()}
          </CollapsingDiv>
          <CollapsingDiv
            title={<img className="qr-code" src={"/qr_fonepay.png"} />}
            textAlign="center"
          >
            {this.renderHowToFonePay()}
          </CollapsingDiv>
          <CollapsingDiv
            title={
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img className="qr-code-big" src="/logo_bank.png" />
                <div
                  style={{
                    color: "#043984",
                    fontWeight: 500,
                    fontSize: "0.95rem",
                    textAlign: "left",
                  }}
                >
                  a/c: <code>{this.state.lang.payments.bankDetails[0]}</code>
                  <br />
                  a/c: <code>{this.state.lang.payments.bankDetails[1]}</code>
                  <br />
                  {this.state.lang.payments.bankDetails[2][0]}:{" "}
                  <code>{this.state.lang.payments.bankDetails[2][1]}</code>
                </div>
              </div>
            }
          >
            {this.renderHowToBankTransfer()}
          </CollapsingDiv>
        </div>
        <div
          className="openAccount-leftPanel"
          style={{
            flex: this.state.paymentHelpContent ? 0.3 : 0,
            textAlign: "center",
            transitionDuration: "500ms",
            background: "#2f2f2f",
            color: "white",
          }}
        >
          {this.state.paymentHelpContent}
        </div>
      </>
    );
  }

  renderFooter() {
    return (
      <div
        style={{
          color: "white",
          fontSize: "0.85rem",
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: "10%",
          paddingRight: "10%",
        }}
      >
        <div>
          <div
            style={{ display: "block", marginTop: "4px", marginBottom: "4px" }}
          >
            JF Securities Pvt. Ltd.
          </div>
        </div>
        <div style={{ textAlign: "right" }}>
          <div
            style={{
              display: "block",
              cursor: "pointer",
              marginTop: "4px",
              marginBottom: "4px",
            }}
          >
            Facebook
          </div>
          <div
            style={{ display: "block", marginTop: "4px", marginBottom: "4px" }}
          >
            {this.state.lang.footer.officeTime}
          </div>
          <div
            style={{ display: "block", marginTop: "4px", marginBottom: "4px" }}
          >
            {this.state.lang.footer.marketTime}
          </div>
          {/* <div style={{ display: "inline-block", marginTop: "4px", marginBottom: "4px", marginLeft: "10px" }}>Instagram</div> */}
        </div>
      </div>
    );
  }

  renderSupportFaqs(title, faqs) {
    return (
      <div className="support-hover-tooltip">
        {title && <div className="support-item-title">{title}</div>}
        <ol className="support-item-faq">
          {faqs.map(function (d, idx) {
            return <li key={idx}>{d}</li>;
          })}
        </ol>
      </div>
    );
  }
  renderSupportOpenNewTradingOnlineRegistration() {
    return (
      <>
        {this.renderSupportFaqs(
          this.state.lang.supportTms.onlineRegistration.steps[0],
          this.state.lang.supportTms.onlineRegistration.steps.slice(1)
        )}
      </>
    );
  }

  renderSupportOpenNewTradingPhysically() {
    return (
      <>
        {this.renderSupportFaqs(
          this.state.lang.supportTms.newTradingPhysically.steps[0],
          this.state.lang.supportTms.newTradingPhysically.steps.slice(1)
        )}
      </>
    );
  }

  renderSupportOpenNewTradingEmail() {
    return (
      <>
        {this.renderSupportFaqs(
          this.state.lang.supportTms.newTradingEmail.steps[0],
          this.state.lang.supportTms.newTradingEmail.steps.slice(1)
        )}
      </>
    );
  }

  renderSupportUpdateKyc() {
    return (
      <>
        {this.renderSupportFaqs(
          this.state.lang.supportTms.updateKyc.steps[0],
          this.state.lang.supportTms.updateKyc.steps.slice(1)
        )}
      </>
    );
  }

  renderSupportResetPassword() {
    return (
      <>
        {this.renderSupportFaqs(
          this.state.lang.supportTms.resetPassword.steps[0],
          this.state.lang.supportTms.resetPassword.steps.slice(1)
        )}
      </>
    );
  }

  renderPaymentForPurchase() {
    return (
      <>
        {this.renderSupportFaqs(
          this.state.lang.supportTms.paymentPurchase.steps[0],
          this.state.lang.supportTms.paymentPurchase.steps.slice(1)
        )}
      </>
    );
  }

  renderSupportBuyShareAndCheckExecution() {
    return (
      <>
        {this.renderSupportFaqs(
          this.state.lang.supportTms.buyShare.how[0],
          this.state.lang.supportTms.buyShare.how.slice(1)
        )}
        {this.renderSupportFaqs(
          this.state.lang.supportTms.buyShare.check[0],
          this.state.lang.supportTms.buyShare.check.slice(1)
        )}
      </>
    );
  }

  renderSupportSellShareAndCheckExecution() {
    return (
      <>
        {this.renderSupportFaqs(
          this.state.lang.supportTms.sellShare.how[0],
          this.state.lang.supportTms.sellShare.how.slice(1)
        )}
        {this.renderSupportFaqs(
          this.state.lang.supportTms.sellShare.check[0],
          this.state.lang.supportTms.sellShare.check.slice(1)
        )}
      </>
    );
  }
  renderSupportOrderEditAndCancelOrder() {
    return (
      <>
        {this.renderSupportFaqs(
          this.state.lang.supportTms.editCancelOrder.edit[0],
          this.state.lang.supportTms.editCancelOrder.edit.slice(1)
        )}
        {this.renderSupportFaqs(
          this.state.lang.supportTms.editCancelOrder.cancel[0],
          this.state.lang.supportTms.editCancelOrder.cancel.slice(1)
        )}
      </>
    );
  }
  renderSupportLoadCollaeral() {
    return (
      <>
        {this.renderSupportFaqs(
          this.state.lang.supportTms.loadCollateral.steps[0],
          this.state.lang.supportTms.loadCollateral.steps.slice(1)
        )}
      </>
    );
  }
  renderSupportRequestRefundCollaeral() {
    return (
      <>
        {this.renderSupportFaqs(
          this.state.lang.supportTms.refundCollateral.steps[0],
          this.state.lang.supportTms.refundCollateral.steps.slice(1)
        )}
      </>
    );
  }

  renderSupportTms() {
    return (
      <div className="support-item">
        <div className="support-item-title">TMS</div>
        <div className="support-item-content">
          <CollapsingDiv
            marginBottom="8px"
            title={this.state.lang.supportTms.onlineRegistration.title}
          >
            {this.renderSupportOpenNewTradingOnlineRegistration()}
          </CollapsingDiv>
          <CollapsingDiv
            marginBottom="8px"
            title={this.state.lang.supportTms.newTradingPhysically.title}
          >
            {this.renderSupportOpenNewTradingPhysically()}
          </CollapsingDiv>
          <CollapsingDiv
            marginBottom="8px"
            title={this.state.lang.supportTms.newTradingEmail.title}
          >
            {this.renderSupportOpenNewTradingEmail()}
          </CollapsingDiv>
          <CollapsingDiv
            marginBottom="8px"
            title={this.state.lang.supportTms.updateKyc.title}
          >
            {this.renderSupportUpdateKyc()}
          </CollapsingDiv>
          <CollapsingDiv
            marginBottom="8px"
            title={this.state.lang.supportTms.resetPassword.title}
          >
            {this.renderSupportResetPassword()}
          </CollapsingDiv>
          <CollapsingDiv
            marginBottom="8px"
            title={this.state.lang.supportTms.paymentPurchase.title}
          >
            {this.renderPaymentForPurchase()}
          </CollapsingDiv>
          <CollapsingDiv
            marginBottom="8px"
            title={this.state.lang.supportTms.buyShare.title}
          >
            {this.renderSupportBuyShareAndCheckExecution()}
          </CollapsingDiv>
          <CollapsingDiv
            marginBottom="8px"
            title={this.state.lang.supportTms.sellShare.title}
          >
            {this.renderSupportSellShareAndCheckExecution()}
          </CollapsingDiv>
          <CollapsingDiv
            marginBottom="8px"
            title={this.state.lang.supportTms.editCancelOrder.title}
          >
            {this.renderSupportOrderEditAndCancelOrder()}
          </CollapsingDiv>
          <CollapsingDiv
            marginBottom="8px"
            title={this.state.lang.supportTms.loadCollateral.title}
          >
            {this.renderSupportLoadCollaeral()}
          </CollapsingDiv>
          <CollapsingDiv
            marginBottom="8px"
            title={this.state.lang.supportTms.refundCollateral.title}
          >
            {this.renderSupportRequestRefundCollaeral()}
          </CollapsingDiv>
        </div>
      </div>
    );
  }

  renderSupportMeroShare() {
    return <div className="support-item"></div>;
  }

  renderSupport() {
    return (
      <div className="support">
        {this.renderTitle(this.state.lang.supportTms.title, true)}
        <div className="support-items">
          {this.renderSupportTms()}
          {this.renderSupportMeroShare()}
        </div>
      </div>
    );
  }

  renderDepartmentContacts(title, email, contacts) {
    const contactItems = [];
    for (let i in contacts) {
      contactItems.push(
        <li className="contact-item" key={i}>
          {contacts[i].name} <code>{this.renderPhone(contacts[i].number)}</code>
        </li>
      );
    }
    return (
      <div className="department-item">
        <div className="department-item-title">
          <div>{title}</div>
          {email && (
            <code className="department-item-email">
              {this.renderEmail(
                email,
                undefined,
                "Need assistance, Please contact me!",
                "Hi,%0A%0AI required some assistance. Please call me on my number xxxxxxxxxx "
              )}
            </code>
          )}
        </div>
        <ol className="contacts">{contactItems}</ol>
      </div>
    );
  }

  redirectToVSupport() {
    let url = decodeURIComponent(
      escape(window.atob(this.state.vSupportLink))
    ).split("::::")[1];
    window.location.href = url;
  }

  KathmanduBranch() {
    return (
      <>
        <JfMap />
        <br />

        <div className="departments">
          {this.renderDepartmentContacts(
            this.state.lang.contactUs.trading,
            undefined,
            [
              { name: "Parbati Karki", number: "9847771057" },
              { name: "Rasila Sharma", number: "9847770434" },
            ]
          )}
          {this.renderDepartmentContacts(
            this.state.lang.contactUs.accounts,
            "accounts@jfsecurities.com",
            [
              { name: "Purnima Ranjitkar - Payin", number: "9861413588" },
              { name: "Deepa Shahi - Payin", number: "9847770533" },
              { name: "Sajana Ranjit - Payout", number: "9847774464" },
              { name: "Nirmala Magar", number: "9847773155" },
              { name: "Dipesh Maharjan", number: "9847773840" },
            ]
          )}
          {this.renderDepartmentContacts(
            this.state.lang.contactUs.operations,
            "operations@jfsecurities.com",
            [
              { name: "Ajay Das Joshi", number: "9843697582" },
              { name: "Yasodha Budhathoki", number: "9847771097" },
              { name: "Pradip Nepali", number: "9847773626" },
            ]
          )}
          {this.renderDepartmentContacts(
            this.state.lang.contactUs.customerCare,
            "support@jfsecurities.com",
            [
              { name: "Muna Moktan", number: "9847773822" },
              { name: "Dipika Shahi", number: "9847773880" },
            ]
          )}
          <div className="telNumbers" style={{ display: "flex" }}>
            {this.state.lang.contactUs.tel}:{" "}
            <code>
              {this.renderPhone("01-5356099")}, {this.renderPhone("01-5348202")},{" "}
              {this.renderPhone("01-5312072")}
            </code>
            <br />
            <code style={{ flex: 1, textAlign: "end" }}>
              {this.renderEmail(
                "info@jfsecurities.com",
                undefined,
                "Need help, Please contact me.",
                "Hi, I need some assistance, please contact me."
              )}
            </code>
          </div>
        </div>
      </>
    );
  }

  DamakBranch() {
    return (
      <>
        <DamakMapMobile />

        <div className="departments">
          {this.renderDepartmentContacts(
            this.state.lang.contactUs.bm,
            undefined,
            [{ name: "Bharat Neupane", number: "9748277123" }]
          )}
          {this.renderDepartmentContacts(
            this.state.lang.contactUs.customerCare,
            "support@jfsecurities.com",
            [{ name: "Umesh Kafle", number: "9748277124" }]
          )}

          {this.renderDepartmentContacts(
            this.state.lang.contactUs.operations,
            "operations@jfsecurities.com",
            [
              { name: "Roshan Kafle", number: "9748277125" },
              { name: "Saugat Gautam", number: "9748277126" },
            ]
          )}

          <div className="telNumbers" style={{ display: "flex" }}>
            {this.state.lang.contactUs.tel}:{" "}
            <code>
              {this.renderPhone("023570540")}, {this.renderPhone("023578540")}
            </code>
            <br />
            <code style={{ flex: 1, textAlign: "end" }}>
              {this.renderEmail(
                "info@jfsecurities.com",
                undefined,
                "Need help, Please contact me.",
                "Hi, I need some assistance, please contact me."
              )}
            </code>
          </div>
        </div>
      </>
    );
  }

  DangBranch() {
    return (
      <>
        <DangMapMobile />
        <div className="departments">
          {this.renderDepartmentContacts(
            this.state.lang.contactUs.bm,
            undefined,
            [{ name: "Sabin Raj Dangi", number: "9748277113" }]
          )}
          {this.renderDepartmentContacts(
            this.state.lang.contactUs.customerCare,
            "support@jfsecurities.com",
            [{ name: "Nirmala Oli", number: "9748277115" }]
          )}

          {this.renderDepartmentContacts(
            this.state.lang.contactUs.accounts,
            "accounts@jfsecurities.com",
            [{ name: "Deepika Basnet", number: "9748277114" }]
          )}

          <div className="telNumbers" style={{ display: "flex" }}>
            {this.state.lang.contactUs.tel}:{" "}
            <code>
              {this.renderPhone("082590172")}, {this.renderPhone("082590173")}
            </code>
            <br />
            <code style={{ flex: 1, textAlign: "end" }}>
              {this.renderEmail(
                "info@jfsecurities.com",
                undefined,
                "Need help, Please contact me.",
                "Hi, I need some assistance, please contact me."
              )}
            </code>
          </div>
        </div>
      </>
    );
  }

  handleBranchButtonClick = (branch) => {
    this.setState({ selectedBranch: branch });
  };

  renderContactUsSection() {
    const { selectedBranch } = this.state;
    return (
      <>
        <div className="contacts-section">
          {this.renderTitle(this.state.lang.contactUs.title)}
          {selectedBranch === 1 ? this.KathmanduBranch() : null}
          {selectedBranch === 2 ? this.DamakBranch() : null}
          {selectedBranch === 3 ? this.DangBranch() : null}

          <div
            style={{
              textAlign: "center",
              fontSize: "1.4rem",
              marginTop: "32px",
              marginBottom: "32px",
            }}
          >
            <div
              style={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={this.redirectToVSupport}
            >
              {this.state.lang.contactUs.joinViber}
            </div>
          </div>

          <div>
            <div style={{ marginBottom: "10px", textAlign: "center" }}>
              {this.state.lang.contactUs.location}
            </div>
            <div
              style={{
                textAlign: "center",
                marginBottom: "10px",
                cursor: "pointer",
                textDecoration: "underline",
                fontSize: "medium",
              }}
              onClick={() => this.handleBranchButtonClick(1)}
            >
              {this.state.lang.contactUs.kathmanduBranch}
            </div>
            <div
              style={{
                textAlign: "center",
                marginBottom: "10px",
                cursor: "pointer",
                textDecoration: "underline",
                fontSize: "medium",
              }}
              onClick={() => this.handleBranchButtonClick(2)}
            >
              {this.state.lang.contactUs.damakBranch}
            </div>
            <div
              style={{
                textAlign: "center",
                marginBottom: "10px",
                cursor: "pointer",
                textDecoration: "underline",
                fontSize: "medium",
              }}
              onClick={() => this.handleBranchButtonClick(3)}
            >
              {this.state.lang.contactUs.dangBranch}
            </div>
          </div>
        </div>
      </>
    );
  }

  render() {
    return (
      <div class="fullpage" style={{ overflowX: "hidden" }}>
        <div id="chart-bg" className="chart-bg"></div>
        <div ref={this.pageMain}>{this.renderMainPage()}</div>
        <div
          ref={this.pageOurServices}
          style={{
            background: "white",
            paddingTop: "32px",
            paddingBottom: "32px",
          }}
        >
          {this.renderOurServices()}
        </div>
        <div
          style={{
            textAlign: "center",
            background: window.innerWidth > 500 ? "#e7e8c6" : "white",
            paddingBottom: window.innerWidth > 500 ? "32px" : "0",
          }}
        >
          <img
            src="/open_account.png"
            style={{
              height: "75vh",
              maxHeight: "75vh",
              paddingTop: "5vh",
              paddingBottom: "5vh",
            }}
          />
        </div>
        <div
          ref={this.pageJoinUs}
          style={{
            background: "#08644c",
            color: "white",
            paddingTop: "32px",
            paddingBottom: "32px",
          }}
        >
          {this.renderOpenAccount()}
        </div>
        <div
          ref={this.pagePayments}
          style={{
            background: "white",
            paddingTop: "32px",
            paddingBottom: "32px",
          }}
        >
          {this.renderPayments()}
        </div>
        <div ref={this.pageSupport} style={{ background: "#08644c" }}>
          {this.renderSupport()}
        </div>
        <div
          ref={this.pageContactUsSection}
          style={{
            background: "white",
            paddingLeft: "10%",
            paddingRight: "10%",
            paddingTop: "32px",
            paddingBottom: "32px",
          }}
        >
          {this.renderContactUsSection()}
        </div>
        <div
          ref={this.pageFooter}
          style={{
            background: "#08644c",
            paddingTop: "20px",
            paddingBottom: "20px",
            paddinLeft: "10%",
            paddinRight: "10%",
            paddinTop: "32px",
            paddinBottom: "32px",
          }}
        >
          {this.renderFooter()}
        </div>
      </div>
    );
  }
}

export default App;

const eng = {
  jfLogo: ["Securities"],
  jfRegistration: [
    "Stock Broker #7: Member of Nepal Stock Exchange",
    "Licensed by: Nepal Securities Board",
  ],
  navOptions: {
    loginZipline: "Market Analysis",
    loginTms: "Login TMS",
    loginMeroShare: "Login Mero Share",
    openAccount: "Open Account",
    makePayment: "Make Payment",
    faqs: "FAQs",
    contactUs: "Contact Us",
  },

  ourServices: {
    ourServices: "Our Services",
    brokeringService: "Brokering Service",
    brokeringServiceCaption:
      "Brokering Service for all kinds of securities listed with NEPSE - stocks, debentures, bonds, mutual fund units etc.",
    dpService: "Depository Services",
    dpServiceCaption:
      "With our joint venture company - Nepal DP Limited, we are providing all DP services",
    marketSolutions: "Market Solutions",
    marketSolutionsCaption: "Trading Solutions, Market Analysis and more",
  },
  joinUs: {
    joinUs: "Join Us",
    openAccount: "Open Account",
    openAccountOnline: "Open Account Online",
    tabs: {
      openDmat: "Open Dmat Account",
      downloadForm: "Download KYC Form",
      updateKyc: "Update KYC",
      required: "Required",
    },
    openDmat: {
      individualDmatForm: "Dmat KYC Form",
      meroshareForm: "MeroShare Form",
      mailDocumentsCaption: [
        "Documents can be mailed to",
        "or submitted to office.",
      ],
    },
    offlineAccount: {
      individualKycForm: "Individual KYC Form",
      institutionKycForm: "Institution KYC Form",
      mailDocumentsCaption: [
        "Documents can be mailed to",
        "or submitted to office.",
      ],
    },
    updateAccount: {
      individualKycForm: "Individual KYC Form",
      institutionKycForm: "Institution KYC Form",
      mailDocumentsCaption: [
        "Documents can be mailed to",
        "with subject",
        "KYC UPDATE",
        "or submitted to office.",
      ],
    },
    requiredDocs: {
      requiredDocuments: "Required Documents",
      adultList: [
        "Duly filled KYC Form",
        "Signed Agreement paper",
        "Citizenship",
        "Passport sized photo",
        "Utility bill (Electricity, Water) or Voter Card",
        "Signature photo",
        "PAN Card",
      ],
      requriedMinorDocuments: "Required Minor Documents",
      minorList: [
        "Birth certificate",
        "Minor passport sized photo",
        "Guardian details as above",
      ],
    },
  },
  howToConnectIps: {
    connectIps: "Connect Ips",
    steps: [
      "Open ConnectIps",
      "Goto Financial Institution",
      ["Select", "Capital Market"],
      "Select Broker Payment",
      "Fill the transaction form",
      ["Select Broker as", "No: 7 J.F. Securities Co. Pvt. Ltd."],
      "Proceed with payment",
    ],
    gotoConnectIps: "Goto ConnectIps",
  },
  howToEsewa: {
    esewa: "Esewa",
    steps: [
      "Open eSewa",
      [
        "Scroll down to",
        "Stock Broker Payment",
        "and click on",
        "J.F. Securities Company Pvt. Ltd.",
      ],
      "Login and then fill the transaction form",
      "Proceed with payment",
    ],
    gotoEsewa: "Goto Esewa",
  },
  howToKhalti: {
    khalti: "Khalti",
    steps: [
      "Open Khalti",
      [
        "Scroll down to",
        "Stock Broker Payment",
        "and click on",
        "J.F. Securities Company Pvt. Ltd.",
      ],
      "Login and then fill the transaction form",
      "Proceed with payment",
    ],
    gotoKhalti: "Goto Khalti",
  },
  howToImePay: {
    imePay: "IME Pay",
    steps: [
      "Open IME Pay",
      [
        "Scroll down and click on",
        " Share Market",
        "and select",
        "Broker Payment.",
      ],
      [
        "Select",
        " J.F. Securities Company Pvt. Ltd.",
        " and fill out other details",
      ],
      "Proceed with payment",
    ],
    gotoImePay: "Goto IME Pay",
  },
  howToFonePay: {
    fonePay: "FonePay",
    steps: ["Open FonePay", "Scan the Qr code", "Proceed with payment"],
  },
  howtoBankTransfer: {
    bankTransfer: "Bank Transfer",
    paymentDetails: "Payment Details",
    paymentDetailsList: [
      "J.F.SECURITIES COMPANY PVT.LTD",
      "07501010000745",
      "Kamaladi",
    ],
    steps: [
      "Open your Netbanking app",
      "Fill transaction form",
      "Proceed with payment",
    ],
  },
  payments: {
    chooseYourPayment: "Choose Your Payment",
    tipConnectIps: "Payment by ConnectIps",
    tipEsewa: "Payment by Esewa",
    tipKhalti: "Payment by Khalti",
    tipImePay: "Payment by IME Pay",
    tipFonePay: "Payment by FonePay",
    tipBankTransfer: "Payment by Bank Transfer",
    bankDetails: [
      "J.F.SECURITIES COMPANY PVT.LTD",
      "07501010000745",
      ["branch", "Kamaladi"],
    ],
    sendReceipt: [
      "Please",
      "write Client Code in remark for transaction",
      "and send the copy of transaction to",
      "or Viber to",
    ],
  },
  footer: {
    officeTime: "Office time: 10AM - 6PM",
    marketTime: "Market time: 11am - 3pm (SUN - THU) Odd Lot (FRI)",
  },
  supportTms: {
    title: "FAQs",
    onlineRegistration: {
      title: "Open NEW Trading/Broker Account (Online Registration)",
      steps: [
        "Online Registration",
        "Open www.jfsecurities.com, click on Open Account and click on Open Account Online",
        "Completely fill the KYC form",
        "Upload all required documents (Citizenship, PP Photo, Utility Bill or Voter card and PAN card",
        "Verify all details are correct",
        "Submit the form and wait for activation",
        "Check your email (spam folder also) in next 2days for activation email",
      ],
    },
    newTradingPhysically: {
      title:
        "Open NEW Trading/Broker Account (By Physical Presence at our office)",
      steps: [
        "By Physical Presence at our office",
        "Get from from helpdesk",
        "Fill the KYC form thoroughly",
        "Attach all required documents (Citizenship, PP Photo, Utility Bill or Voter card and PAN card",
        "Verify all details are correct",
        "Submit the form and wait for activation",
        "Check your email (spam folder also) in next 2days for activation email",
      ],
    },
    newTradingEmail: {
      title:
        "Open NEW Trading/Broker Account (KYC form submission through email)",
      steps: [
        "KYC form submission through email",
        "Download KYC from from www.jfsecurities.com",
        "Attach all required documents (Citizenship, PP Photo, Utility Bill/Voter card and PAN card",
        "Verify all details are correct",
        "Submit email with all documents to operations@jfsecurities.com",
        "Check your email (spam folder also) in next 2days for activation email",
      ],
    },
    updateKyc: {
      title: "Update KYC(for existing Trading/Broker Account only)",
      steps: [
        "Update KYC(for existing Trading/Broker Account only)",
        "Download KYC from from www.jfsecurities.com",
        "Attach all required documents (Citizenship, PP Photo, Utility Bill or Voter card and PAN card",
        "Verify all details are correct",
        "Submit email with all documents to operations@jfsecurities.com",
        "Check your email (spam folder also) in next 2days for activation email",
      ],
    },
    resetPassword: {
      title: "Forgot Password/ Reset Password",
      steps: [
        "Forgot Password/ Reset Password",
        "Open www.jfsecurities.com and click on Login TMS",
        "Click on Forget Password",
        "Enter your Username/Client Code and email address",
        "Click on Submit button",
        "You will receive an email to Reset Password",
        "Click on the link to reset your password",
        "Reset your password as instructed and submit",
        "You are done now",
      ],
    },
    paymentPurchase: {
      title: "Make Payment for Purchases",
      steps: [
        "Make Payment for Purchases",
        "Login to TMS",
        "Go to Fund Management or My Information",
        "Go to Fund Settlement",
        "Click Buy Information",
        "Click Bank icon under Action tab for the Buy Bills for payment",
        "Click Proceed with Payment",
        "Enter your Username and Password for login",
        "Tick on EOD Pay in",
        "Enter Amount, Select connectips, write clientId in Remark",
        "Click on Make Payment",
        "Click Confirm Payment",
        "You will be redirected to connectips",
        "Enter login details",
        "Enter all payment details and click on submit",
        "Check Client Fund Transfer History to know payment status",
      ],
    },
    buyShare: {
      title: "Buy share and check executed order in TMS",
      how: [
        "How to buy stock?",
        "Go to TMS Dashboard",
        "Go to Order Management",
        "Click Buy/Sell",
        "Enter [ symbol, quantity, price, validity ]",
        "Choose Buy option",
        "Press Buy Button",
        "You have successfully placed buy order",
      ],
      check: [
        "How to check executed buy order?",
        "Go to Daily Order Book",
        "Select “Completed” Tab",
        "Recent list of order will be displayed with “Completed” Status",
      ],
    },
    sellShare: {
      title: "Sell share and check executed order in TMS",
      how: [
        "How to sell stock?",
        "Go to TMS Dashboard",
        "Go to Order Management",
        "Click Buy/Sell",
        "Enter [ symbol, quantity, price, validity ]",
        "Choose Sell option",
        "Press Sell Button",
      ],
      check: [
        "How to check executed sell order?",
        "Go to Daily Order Book",
        "Select “Completed” Tab",
        "Recent list of order will be displayed with “Completed” Status",
      ],
    },
    editCancelOrder: {
      title: "Edit and cancel order in TMS",
      edit: [
        "How to edit buy/sell order?",
        "Go to TMS Dashboard",
        "Go to Order Management",
        "Click Buy/Sell",
        "Go to Action",
        "Click Modify",
        "Edit Quantity and Price you want to Change",
        "Again Press Buy Button to place Modify Order",
      ],
      cancel: [
        "How to cancel buy/sell order?",
        "Go to TMS Dashboard",
        "Go to Order Management",
        "Click Buy/Sell",
        "Go to Action",
        "Click Cancel",
        "Select “Yes” to cancel order",
        "You successfully cancel the buy order",
      ],
    },
    loadCollateral: {
      title: "Load collateral from Connect IPS",
      steps: [
        "Load Collateral from Connect IPS",
        "Log in to TMS Dashboard",
        "Go to Fund Management",
        "Click Collateral Management",
        "Click Load Collateral",
        "Select Connect IPS from Bank section",
        "Enter Amount you want to Transfer for trading",
        "Write your Client ID in remark",
        "Click Submit and press “Yes” for transfer",
        "Sign in to Connect IPS",
        "Select Debit Account and press submit",
        "Enter OTP and click submit",
        "Your collateral has been loaded in your TMS",
      ],
    },
    refundCollateral: {
      title: "Request collateral Refund from TMS",
      steps: [
        "Refund Collateral from TMS Steps",
        "Go to TMS Dashboard",
        "Click to Fund Management",
        "Click Collateral Management",
        "Click Refund Collateral",
        "Enter Account Number and Amount",
        "Click submit for the transfer request",
      ],
    },
  },
  contactUs: {
    title: "Contact Us",
    trading: "Trading",
    accounts: "Accounts",
    operations: "Operations",
    customerCare: "Customer Care",
    tel: "tel",
    joinViber: "Join our Viber Support",
    kathmanduBranch: "Kathmandu",
    damakBranch: "Damak",
    dangBranch: "Tulsipur",
    location: "Our Locations",
    bm: "Branch Manager",
  },
};

const nep = {
  jfLogo: ["सेक्युरिटिज"],
  jfRegistration: [
    "स्टक ब्रोकर नं ७: नेपाल स्टक एक्सचेञ्ज",
    "नेपाल धितोपत्र बोर्ड बाट अनुमति प्राप्त",
  ],
  navOptions: {
    loginZipline: "बजार विश्लेषण",
    loginTms: "TMS लगईन गर्नुहोस",
    loginMeroShare: "MeroShare लगईन गर्नुहोस",
    openAccount: "खाता खोल्नुहोस",
    makePayment: "भुक्तानी गर्नुहोस",
    faqs: "प्रायः सोधिने प्रश्नहरू",
    contactUs: "सम्पर्क गर्नुहोस",
  },
  ourServices: {
    ourServices: "हाम्रा सेवाहरु",
    brokeringService: "धितोपत्र ब्रोकर (खरीदबिक्री) सेवा",
    brokeringServiceCaption:
      "नेप्सेमा सुचिकरण भएका शेयर, डिवेञ्चर, ऋणपत्र, म्युचुअल फण्ड ईकाई जस्ता सबै प्रकारका धितोपत्रहरुको खरीद बिक्री ब्रोकर सेवा",
    dpService: "धितोपत्र निक्षेप सेवा",
    dpServiceCaption:
      "हाम्रा संयुक्त लगानी कम्पनी नेपाल डिपी लिमिटेड संगको सहकार्यमा सबै प्रकारका निक्षेप सेवाहरु ",
    marketSolutions: "बजार समाधान र प्रबिधी",
    marketSolutionsCaption: "कारोवार समाधान, बजार विश्लेषण र अन्य",
  },
  joinUs: {
    joinUs: "खाता खोल्नुहोस्",
    openAccount: "खाता खोल्नुहोस्",
    openAccountOnline: "अनलाईन खाता खोल्नुहोस",
    tabs: {
      openDmat: "धितोपत्र खाता खोल्नुहोस्",
      downloadForm: "KYC फाराम डाउनलोड गर्नुहोस्",
      updateKyc: "KYC अपडेट गर्नुहोस्",
      required: "आवश्यक कागजातहरु",
    },
    openDmat: {
      individualDmatForm: "धितोपत्र KYC फाराम",
      meroshareForm: "MeroShare फाराम",
      mailDocumentsCaption: ["कागजातहरू", "मा पठाउन वा अफिसमा पेश गर्न सकिन्छ"],
    },
    offlineAccount: {
      individualKycForm: "व्यक्तिगत KYC फाराम",
      institutionKycForm: "संस्था KYC फाराम",
      mailDocumentsCaption: ["कागजातहरू", "मा पठाउन वा अफिसमा पेश गर्न सकिन्छ"],
    },
    updateAccount: {
      individualKycForm: "व्यक्तिगत KYC फाराम",
      institutionKycForm: "संस्था KYC फाराम",
      mailDocumentsCaption: [
        "कागजातहरू",
        "मा",
        "KYC UPDATE",
        "विषय उल्लेख गरि पठाउनु हुन वा अफिसमा बुझाउनु हुन अनुरोध छ।",
      ],
    },
    requiredDocs: {
      requiredDocuments: "आवश्यक कागजातहरू",
      adultList: [
        "पूर्ण रूपमा भरिएको KYC फाराम",
        "हस्ताक्षर गरिएको ग्राहक सम्झौता पत्र",
        "नागरिकता",
        "पासपोर्ट साइजको फोटो",
        "बिजुली वा खानेपानीको महसुल बिल वा मतदाता कार्ड",
        "हस्ताक्षरको फोटो",
        "स्थायी लेखा नम्बर (PAN) कार्ड",
      ],
      requriedMinorDocuments: "आवश्यक नाबालक कागजातहरू",
      minorList: [
        "जन्मदर्ता प्रमाण पत्र",
        "नाबालकको  फोटो",
        "अभिभावकको विवरणहरू माथि भनिए अनुसार",
      ],
    },
  },
  howToConnectIps: {
    connectIps: "Connect IPS",
    steps: [
      "ConnectIPS खोल्नुहोस्",
      "Financial Institution मा जानुहोस्",
      ["Capital Market", "मा क्लीक गर्नुहोस्"],
      "Broker Payment रोज्नुहोस् ",
      "Broker Payment फाराम भर्नुहोस्",
      [
        "Sekect Broker मा लिस्ट बाट चयन गर्नुहोस्",
        "No: 7 J.F. Securities Co. Pvt. Ltd.",
      ],
      "सबै विवरणहरू भरेको ठीक भए Transfer बटन क्लीक गरि भुक्तानी गर्नुहोस",
    ],
    gotoConnectIps: "मलाई Connect IPS मा लैजानुहोस्",
  },
  howToEsewa: {
    esewa: "Esewa",
    steps: [
      "ESewa खोल्नुहोस्",
      [
        "तल स्क्रोल गरि",
        "Stock Broker Payment",
        " खोजी",
        "J.F. Securities Company Pvt. Ltd. मा क्लिक गर्नुहोस् ",
      ],
      "लगईन गर्नुहोस् र भुक्तानी फाराममा सबै विवरणहरू भर्नुहोस्",
      "Proceed बटन क्लीक गरि भुक्तानी गर्नुहोस्",
    ],
    gotoEsewa: "मलाई Esewa मा लैजानुहोस्",
  },
  howToKhalti: {
    khalti: "Khalti",
    steps: [
      "Khalti खोल्नुहोस्",
      [
        "तल स्क्रोल गरि",
        "Stock Broker Payment",
        " खोजी",
        "J.F. Securities Company Pvt. Ltd. मा क्लिक गर्नुहोस् ",
      ],
      "लगईन गर्नुहोस् र भुक्तानी फाराममा सबै विवरणहरू भर्नुहोस्",
      "Proceed बटन क्लीक गरि भुक्तानी गर्नुहोस्",
    ],
    gotoKhalti: "मलाई Khalti मा लैजानुहोस्",
  },
  howToImePay: {
    imePay: "IME Pay",
    steps: [
      "IME Pay खोल्नुहोस्",
      [
        "तल स्क्रोल गरि",
        "Share Market",
        " खोजी",
        "Broker Payment मा क्लिक गर्नुहोस् ",
      ],
      [
        "Stock Broker मा",
        " J.F. Securities Company Pvt. Ltd.",
        " मा select गरि",
        "थप विवरणहरु भर्नुहोस्",
      ],

      // "लगईन गर्नुहोस् र भुक्तानी फाराममा सबै विवरणहरू भर्नुहोस्",
      "Proceed बटन क्लीक गरि भुक्तानी गर्नुहोस्",
    ],
    gotoImePay: "मलाई IME Pay मा लैजानुहोस्",
  },
  howToFonePay: {
    fonePay: "FonePay",
    steps: [
      "FonePay खोल्नुहोस्",
      "QR कोड Scan गर्नुहोस् र भुक्तानी फाराममा सबै विवरणहरू भर्नुहोस्",
      "Proceed बटन क्लीक गरि भुक्तानी गर्नुहोस्",
    ],
  },
  howtoBankTransfer: {
    bankTransfer: " बैंक ट्रान्सफर",
    paymentDetails: "बैंक खाताको विवरण",
    paymentDetailsList: [
      "खाताको नामः J.F.SECURITIES COMPANY PVT.LTD",
      "खाता नं.: 7501010000745",
      "बैंकको शाखाः Kamaladi",
    ],
    steps: [
      "तपाइँको नेटबैंकिंग App खोल्नुहोस्",
      "भुक्तानी फाराम भर्नुहोस्",
      "Submit वा Proceed बटन क्लीक भुक्तानी गर्नुहोस् वा बैंकमा गइ रकम जम्मा गरि Deposit Slip हकमीलाइ पठाउनुहोस",
    ],
  },
  payments: {
    chooseYourPayment: "भुक्तानी माध्यम छनौट गरि भुक्तानी गर्नुहोस्",
    tipConnectIps: "ConnectIps बाट भुक्तानी",
    tipEsewa: "Esewa बाट भुक्तानी",
    tipKhalti: "Khalti बाट भुक्तानी",
    tipImePay: "IME Pay बाट भुक्तानी",
    tipFonePay: "FonePay बाट भुक्तानी",
    tipBankTransfer: "Bank Transfer बाट भुक्तानी",
    bankDetails: [
      "J.F.SECURITIES COMPANY PVT.LTD",
      "07501010000745",
      ["साखा", "Kamaladi"],
    ],
    sendReceipt: [
      "कृपया",
      "पठाउने फारामको रिमार्कमा तपाइंको ग्राहक कोड लेख्नुहोस",
      "र भुक्तानी पुर्जा डाउनलोड गरि",
      "वा Viber मा पठाउनुहोस् - ",
    ],
  },
  footer: {
    officeTime: "कार्यालय समय: बिहान १० बजे देखि साँझ ६ बजे सम्म",
    marketTime:
      "कारोवार समय: बिहान ११ बजे देखि अपराह्न ३ बजे सम्म (अइतबार देखि बिहिबार) अड लट कारोवार (शुक्रबार)",
  },

  supportTms: {
    title: "प्रायः सोधिने प्रश्नहरू",
    onlineRegistration: {
      title: "नयाँ अनलाइन कारोवार/ब्रोकर खाता खोल्ने",
      steps: [
        "नयाँ अनलाइन कारोवार/ब्रोकर खाता खोल्ने ",
        "www.jfsecurities.com खोली खाता खोल्नुहोसमा क्लिक गर्नुहोस",
        "KYC फाराममा आवश्यक सबै विवरणहरू पुर्ण रूपले भर्नुहोस",
        "सबै कागजातहरू (नागरिकताको प्रमाणपत्र, PP साइजको फोटो, बिजुली/खानेपानी महसुल बिल वा मतदाता परिचयपत्र र PAN कार्ड अपलोड गर्नुहोस",
        "सबै ठिक भए फाराम सबमिट गर्नुहोस र खाता एकटीभेशनका लागी पर्खनुहोस",
        "अब तपाइ आफ्नो इमेल खोली इनबक्स चेक गर्नुहोस (Spam फोल्डर समेत)",
        "इमेलमा खाता खोलिएको जानकारी र पासवर्ड सेट गर्ने लिंक आएको हुन्छ, त्यो लिंकमा क्लीक गरि पासवर्ड सेट गर्नुहोस",
      ],
    },
    newTradingPhysically: {
      title: "कार्यालयमा उपस्थित भइ नयाँ कारोवार/ब्रोकर खाता खोल्ने",
      steps: [
        "कार्यालयमा उपस्थित भइ नयाँ कारोवार/ब्रोकर खाता खोल्ने",
        "ग्राहक सेवा कक्ष बाट KYC फाराम प्राप्त गर्नुहोस",
        "KYC फाराममा आवश्यक सबै विवरणहरू पुर्ण रूपले भर्नुहोस",
        "सबै कागजातहरू (नागरिकताको प्रमाणपत्र, PP साइजको फोटो, बिजुली/खानेपानी महसुल बिल वा मतदाता परिचयपत्र र PAN कार्ड संलग्न गर्नुहोस",
        "भरिएका विवरणहरू सबै रूजु गरि ठिक भएको यकीन गर्नुहोस",
        "सबै ठिक भए फाराम बुझाउनुहोस र खाता एकटीभेशनका लागी पर्खनुहोस",
        "अब तपाइ आफ्नो इमेल खोली इनबक्स चेक गर्नुहोस (Spam फोल्डर समेत)",
        "तपाइको इमेलमा खाता खोलिएको जानकारी र पासवर्ड सेट गर्ने लिंक आएको हुन्छ, त्यो लिंकमा क्लीक गरि पासवर्ड सेट गर्नुहोस",
      ],
    },
    newTradingEmail: {
      title: "ईमेल बाट नयाँ कारोवार/ब्रोकर खाता खोल्ने ",
      steps: [
        "ईमेल बाट नयाँ कारोवार/ब्रोकर खाता खोल्ने ",
        "www.jfsecurities.com खोली खाता खोल्नुहोसमा क्लिक गर्नुहोस",
        "फाराम डाउनलोड गर्नुहोसमा क्लिक गरि KYC फाराम गर्नुहोस र प्रिन्ट गर्नुहोस",
        "KYC फाराममा आवश्यक सबै विवरणहरू पुर्ण रूपले भर्नुहोस र स्क्यान गर्नुहोस वा भरेको फारामको फोटो खिच्नुहोस",
        "सबै कागजातहरू (नागरिकताको प्रमाणपत्र, PP साइजको फोटो, बिजुली/खानेपानी महसुल बिल वा मतदाता परिचयपत्र र PAN कार्ड संलग्न गर्नुहोस",
        "भरिएका विवरणहरू सबै रूजु गरि ठिक भएको यकीन गर्नुहोस",
        "सबै ठिक भए फाराम र कागजातहरू एटाचमेन्ट गरि operations@jfsecurities.com मा पठाउनुहोस र खाता एकटीभेशनका लागी पर्खनुहोस",
        "अब तपाइ आफ्नो इमेल खोली इनबक्स चेक गर्नुहोस (Spam फोल्डर समेत)",
        "तपाइको इमेलमा खाता खोलिएको जानकारी र पासवर्ड सेट गर्ने लिंक आएको हुन्छ, त्यो लिंकमा क्लीक गरि पासवर्ड सेट गर्नुहोस",
      ],
    },
    updateKyc: {
      title: "KYC अपडेट गर्नुहोस् (चालु खाताका लागि मात्र)",
      steps: [
        "KYC अपडेट गर्नुहोस् (चालु खाताका लागि मात्र)",
        "www.jfsecurities.com खोली खाता खोल्नुहोसमा क्लिक गर्नुहोस",
        "फाराम डाउनलोड गर्नुहोसमा क्लिक गरि KYC फाराम डाउनलोड गर्नुहोस र प्रिन्ट गर्नुहोस",
        "KYC फाराममा आवश्यक सबै विवरणहरू पुर्ण रूपले भर्नुहोस र स्क्यान गर्नुहोस वा भरेको फारामको फोटो खिच्नुहोस",
        "सबै कागजातहरू (नागरिकताको प्रमाणपत्र, PP साइजको फोटो, बिजुली/खानेपानी महसुल बिल वा मतदाता परिचयपत्र र PAN कार्ड संलग्न गर्नुहोस",
        "भरिएका विवरणहरू सबै रूजु गरि ठिक भएको यकीन गर्नुहोस",
        "सबै ठिक भए फाराम र कागजातहरू एटाचमेन्ट गरि operations@jfsecurities.com मा पठाउनुहोस र खाता एकटीभेशनका लागी पर्खनुहोस",
        "अब तपाइ आफ्नो इमेल खोली इनबक्स चेक गर्नुहोस (Spam फोल्डर समेत)",
        "इमेलमा खाता खोलिएको जानकारी र पासवर्ड सेट गर्ने लिंक आएको हुन्छ, त्यो लिंकमा क्लीक गरि पासवर्ड सेट गर्नुहोस",
      ],
    },
    resetPassword: {
      title: "पासवर्ड बिर्सनुभयो / पासवर्ड रिसेट गर्ने",
      steps: [
        "पासवर्ड बिर्सनुभयो / पासवर्ड रिसेट गर्ने",
        "www.jfsecuruties.com खोली TMS लगईन गर्नुहोसमा क्लिक गर्नुहोस",
        "Forget Password मा क्लिक गर्नुहोस",
        "आवश्यक विवरणहरू (तपाइको युजरनेम/क्ल्इन्टकोड र इमेल ठेगाना) भर्नुहोस ",
        "सबमिट बटन क्लिक गर्नुहोस",
        "अब तपाइ आफ्नो इमेल खोली इनबक्स चेक गर्नुहोस (Spam फोल्डर समेत)",
        "इमेलमा पासवर्ड सेट गर्ने लिंक आएको हुन्छ,",
        "त्यो लिंकमा क्लीक गरि दिइएको निर्देशन अनुसार नयां पासवर्ड रिसेट गर्नुहोस",
        "अब तपाइको नयां पासवर्ड रिसेट भयो",
      ],
    },
    paymentPurchase: {
      title: "खरीदको भुक्तानी गर्ने",
      steps: [
        "खरीदको भुक्तानी गर्ने",
        "TMS मा Fund Management वा My Information मा क्लीक गर्नुहोस",
        "Fund Settlement मा क्लीक गर्नुहोस",
        "Buy information मा क्लीक गर्नुहोस",
        "Fund Settlement मा क्लीक गर्नुहोस",
        "Buy Bill का लागी Action tab को अन्तरगत बैंक आइकन मा क्लीक गर्नुहोस",
        "अर्को पेज खुल्नेछ, त्यसमा Proceed with Payment मा क्लीक गर्नुहोस",
        "EOD Pay In को box मा टिक लगाउनुहोस",
        "TOTAL PAYMENT (NPR) मा माथी Net Amount Payable by Client (NPR) को रकम बराबरको रकम इण्ट्री गर्नुहोस",
        "BANK* मा CONNECT IPS(CIPS) सेलेक्ट गर्नुहोस ",
        "Remarks मा तपाइको आफ्नो क्लाइण्ट आइडि लेख्नुहोस",
        "Make Payment मा क्लीक गर्नुहोस",
        "Confirm Payment मा क्लीक गर्नुहोस",
        "Connect IPS को Login पेज खुल्नेछ, त्यसमा Username र Password राखी लगइन गर्नुहोस",
        "ACCOUNT DETAILS मा आफ्नो Debit Account सेलेक्ट गर्नुहोस र Submit मा क्लीक गर्नुहोस",
        "Client Fund Transfer History मा गइ भुक्तानी Success भयो भएन हेर्नुहोस",
      ],
    },
    buyShare: {
      title: "शेयर खरीद आदेश राख्ने",
      how: [
        "शेयर खरीद आदेश राख्ने",
        "TMS को Dashboard मा जानुहोस",
        "Order Management मा क्लीक गर्नुहोस",
        "Buy/Sell मा क्लीक गर्नुहोस",
        "Buy/Sell गर्ने पेजमा माथी दांया कुनामा भएको BUY SELL बटनलाइ खरीद गर्ने भए BUY तर्फ सार्नुहोस",
        "बटनलाइ BUY तर्फ सार्दा निलो रङमा परिणत हुन्छ",
        "Symbol, Quantity, Price र Validity इण्ट्री गर्नुहोस र खरीद गर्ने भए BUY मा क्लीक गर्नुहोस",
      ],
      check: [
        "शेयर खरीद आदेश जाँच गर्ने",
        "Order Book मा क्लीक गरि खरीद/बिक्री आदेश दर्ता भयो भएन चेक गर्नुहोस",
        "Trade Book मा क्लीक गरि खरीद बिक्री भयो भएन चेक गर्नुहोस",
      ],
    },
    sellShare: {
      title: "शेयर बिक्री आदेश राख्ने",
      how: [
        "शेयर बिक्री आदेश राख्ने",
        "TMS को Dashboard मा जानुहोस",
        "Order Management मा क्लीक गर्नुहोस",
        "Buy/Sell मा क्लीक गर्नुहोस",
        "Buy/Sell गर्ने पेजमा माथी दांया कुनामा भएको BUY SELL बटनलाइ खरीद गर्ने भए SELL तर्फ सार्नुहोस",
        "बटनलाइ SELLतर्फ सार्दा रातो रङमा परिणत हुन्छ",
        "Symbol, Quantity, Price र Validity इण्ट्री गर्नुहोस र बिक्री गर्ने भए SELL मा क्लीक गर्नुहोस",
      ],
      check: [
        "शेयर बिक्री आदेश जाँच गर्ने",
        "Order Book मा क्लीक गरि बिक्री आदेश दर्ता भयो भएन चेक गर्नुहोस",
        "Trade Book मा क्लीक गरि बिक्री भयो भएन चेक गर्नुहोस",
      ],
    },
    editCancelOrder: {
      title: "TMS मा अर्डर सच्याउने र रद्द गर्ने",
      edit: [
        "कसरी खरीद / बिक्री अर्डर सच्याउने?",
        "TMS Dashboard मा जानुहोस्",
        "Order Management मा जानुहोस्",
        "Buy/Sell क्लिक गर्नुहोस्",
        "Action मा जानुहोस्",
        "Modify क्लिक गर्नुहोस्",
        "Quantity र Price मात्र सच्याउन सकिन्छ",
        "फेरी खरीद बटन थिच्नुहोस्",
      ],
      cancel: [
        "कसरी खरीद / बिक्री आदेश रद्द गर्ने?",
        "TMS Dashboard मा जानुहोस्",
        "Order Management मा जानुहोस्",
        "Buy/Sell क्लिक गर्नुहोस्",
        "Action मा जानुहोस्",
        "Cancel क्लिक गर्नुहोस्",
        "अर्डर रद्द गर्न “Yes“ चयन गर्नुहोस्",
      ],
    },
    loadCollateral: {
      title: "Connect IPS बाट Collateral लोड गर्नुहोस्",
      steps: [
        "Connect IPS बाट भुक्तानी गर्दा",
        "TMS Dashboard मा जानुहोस्",
        "Fund Management मा जानुहोस्",
        "Collateral Management क्लिक गर्नुहोस्",
        "Load Collateral क्लिक गर्नुहोस्",
        "Bank Section बाट Connect IPS रोज्नुहोस् ",
        "रिमार्कमा Client Id लेख्नुहोस्",
        "Payment मा क्लिक गर्नु होस् र “Yes“ थिच्नुहोस्",
        "Connect IPS मा साइन इन गर्नुहोस्",
        "Debit Account मा तपाइको बैंक खाता रोज्नुहोस् र Submit थिच्नुहोस्",
        "OTP लेख्नुहोस् र Submit बटन क्लिक गर्नुहोस् ",
        "अब तपाईंको Collateral तपाईंको TMS मा लोड भयो",
      ],
    },
    refundCollateral: {
      title: "TMS बाट Collateral फिर्ता भुक्तानी लिन",
      steps: [
        "TMS बाट Collateral फिर्ता भुक्तानी लिन अनुरोध गर्नुहोस्",
        "TMS Dashboard मा जानुहोस्",
        "Fund Management मा जानुहोस्",
        "Click Collateral Management क्लिक गर्नुहोस्",
        "Refund Collateral क्लिक गर्नुहोस्",
        "खाता नम्वर र रकम लेख्नुहोस् गर्नुहोस्",
        "Refund बटन क्लिक गर्नुहोस",
      ],
    },
  },
  contactUs: {
    title: "हामीलाई सम्पर्क गर्नुहोस",
    trading: "ट्रेडिंग",
    accounts: "लेखा",
    operations: "संचालन",
    customerCare: "ग्राहक सेवा",
    tel: "टेलिफोन",
    joinViber: "Viber सपोर्ट ग्रूप जोइन गर्नुहोस ",
    kathmanduBranch: "काठमाडौं",
    damakBranch: "दमक",
    dangBranch: "तुल्सीपुर",
    location: "हाम्रो ठेगाना",
    bm: "कार्यालय प्रमुख",
  },
};

export default {
  eng,
  nep,
};

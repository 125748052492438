import { createChart } from "lightweight-charts";

const run = () => {
  const initialData = [
    { time: "2018-10-19", value: 327.19, close: 327.19, open: 327.22, high: 327.22, low: 327.14 },
    { time: "2018-10-22", value: 327.16, close: 327.16, open: 327.18, high: 327.18, low: 327.1 },
    { time: "2018-10-23", value: 327.12, close: 327.12, open: 327.15, high: 327.18, low: 327.12 },
    { time: "2018-10-24", value: 327.13, close: 327.13, open: 327.11, high: 327.19, low: 327.11 },
    { time: "2018-10-25", value: 327.13, close: 327.13, open: 327.12, high: 327.14, low: 327.12 },
    { time: "2018-10-26", value: 327.14, close: 327.14, open: 327.12, high: 327.19, low: 327.1 },
    { time: "2018-10-29", value: 327.16, close: 327.16, open: 327.13, high: 327.18, low: 327.1 },
    { time: "2018-10-30", value: 327.21, close: 327.21, open: 327.15, high: 327.29, low: 327.15 },
    { time: "2018-10-31", value: 327.25, close: 327.25, open: 327.2, high: 327.25, low: 327.2 },
    { time: "2018-11-01", value: 327.24, close: 327.24, open: 327.24, high: 327.29, low: 327.22 },
    { time: "2018-11-02", value: 327.21, close: 327.21, open: 327.23, high: 327.25, low: 327.21 },
    { time: "2018-11-05", value: 327.21, close: 327.21, open: 327.2, high: 327.24, low: 327.2 },
    { time: "2018-11-06", value: 327.21, close: 327.21, open: 327.2, high: 327.28, low: 327.2 },
    { time: "2018-11-07", value: 327.22, close: 327.22, open: 327.2, high: 327.27, low: 327.2 },
    { time: "2018-11-08", value: 327.3, close: 327.3, open: 327.21, high: 327.36, low: 327.21 },
    { time: "2018-11-09", value: 327.32, close: 327.32, open: 327.29, high: 327.37, low: 327.29 },
    { time: "2018-11-12", value: 327.31, close: 327.31, open: 327.31, high: 327.36, low: 327.31 },
    { time: "2018-11-13", value: 327.35, close: 327.35, open: 327.3, high: 327.37, low: 327.3 },
    { time: "2018-11-14", value: 327.38, close: 327.38, open: 327.34, high: 327.38, low: 327.3 },
    { time: "2018-11-15", value: 327.34, close: 327.34, open: 327.37, high: 327.38, low: 327.34 },
    { time: "2018-11-16", value: 327.34, close: 327.34, open: 327.33, high: 327.39, low: 327.31 },
    { time: "2018-11-19", value: 327.34, close: 327.34, open: 327.33, high: 327.39, low: 327.33 },
    { time: "2018-11-20", value: 327.39, close: 327.39, open: 327.33, high: 327.39, low: 327.33 },
    { time: "2018-11-21", value: 327.42, close: 327.42, open: 327.38, high: 327.46, low: 327.38 },
    { time: "2018-11-23", value: 327.41, close: 327.41, open: 327.41, high: 327.42, low: 327.41 },
    { time: "2018-11-26", value: 327.41, close: 327.41, open: 327.4, high: 327.45, low: 327.4 },
    { time: "2018-11-27", value: 327.45, close: 327.45, open: 327.4, high: 327.49, low: 327.4 },
    { time: "2018-11-28", value: 327.34, close: 327.34, open: 327.44, high: 327.44, low: 327.3 },
    { time: "2018-11-29", value: 327.47, close: 327.47, open: 327.33, high: 327.48, low: 327.33 },
    { time: "2018-11-30", value: 327.34, close: 327.34, open: 327.46, high: 327.46, low: 327.34 },
    { time: "2018-12-03", value: 327.34, close: 327.34, open: 327.33, high: 327.39, low: 327.32 },
    { time: "2018-12-04", value: 327.35, close: 327.35, open: 327.33, high: 327.39, low: 327.33 },
    { time: "2018-12-06", value: 327.46, close: 327.46, open: 327.34, high: 327.49, low: 327.34 },
    { time: "2018-12-07", value: 327.53, close: 327.53, open: 327.45, high: 327.57, low: 327.45 },
    { time: "2018-12-10", value: 327.56, close: 327.56, open: 327.52, high: 327.57, low: 327.52 },
    { time: "2018-12-11", value: 327.61, close: 327.61, open: 327.55, high: 327.64, low: 327.55 },
    { time: "2018-12-12", value: 327.63, close: 327.63, open: 327.6, high: 327.63, low: 327.6 },
    { time: "2018-12-13", value: 327.63, close: 327.63, open: 327.62, high: 327.64, low: 327.62 },
    { time: "2018-12-14", value: 327.64, close: 327.64, open: 327.62, high: 327.66, low: 327.61 },
    { time: "2018-12-17", value: 327.63, close: 327.63, open: 327.63, high: 327.66, low: 327.63 },
    { time: "2018-12-18", value: 327.62, close: 327.62, open: 327.62, high: 327.67, low: 327.62 },
    { time: "2018-12-19", value: 327.65, close: 327.65, open: 327.61, high: 327.66, low: 327.61 },
    { time: "2018-12-20", value: 327.62, close: 327.62, open: 327.64, high: 327.67, low: 327.62 },
    { time: "2018-12-21", value: 327.57, close: 327.57, open: 327.61, high: 327.61, low: 327.53 },
    { time: "2018-12-24", value: 327.48, close: 327.48, open: 327.56, high: 327.56, low: 327.4 },
    { time: "2018-12-26", value: 327.41, close: 327.41, open: 327.47, high: 327.47, low: 327.41 },
    { time: "2018-12-27", value: 327.45, close: 327.45, open: 327.4, high: 327.47, low: 327.4 },
    { time: "2018-12-28", value: 327.45, close: 327.45, open: 327.44, high: 327.49, low: 327.42 },
    { time: "2018-12-31", value: 327.53, close: 327.53, open: 327.44, high: 327.54, low: 327.44 },
    { time: "2019-01-02", value: 327.53, close: 327.53, open: 327.52, high: 327.55, low: 327.51 },
    { time: "2019-01-03", value: 327.56, close: 327.56, open: 327.52, high: 327.58, low: 327.51 },
    { time: "2019-01-04", value: 327.56, close: 327.56, open: 327.55, high: 327.59, low: 327.55 },
    { time: "2019-01-07", value: 327.57, close: 327.57, open: 327.55, high: 327.57, low: 327.52 },
    { time: "2019-01-08", value: 327.59, close: 327.59, open: 327.56, high: 327.59, low: 327.51 },
    { time: "2019-01-09", value: 327.6, close: 327.6, open: 327.58, high: 327.67, low: 327.58 },
    { time: "2019-01-10", value: 327.67, close: 327.67, open: 327.59, high: 327.67, low: 327.59 },
    { time: "2019-01-11", value: 327.66, close: 327.66, open: 327.66, high: 327.68, low: 327.6 },
    { time: "2019-01-14", value: 327.65, close: 327.65, open: 327.65, high: 327.65, low: 327.65 },
    { time: "2019-01-15", value: 327.7, close: 327.7, open: 327.64, high: 327.79, low: 327.64 },
    { time: "2019-01-16", value: 327.75, close: 327.75, open: 327.69, high: 327.79, low: 327.69 },
    { time: "2019-01-17", value: 327.72, close: 327.72, open: 327.74, high: 327.79, low: 327.72 },
    { time: "2019-01-18", value: 327.76, close: 327.76, open: 327.71, high: 327.76, low: 327.71 },
    { time: "2019-01-22", value: 327.81, close: 327.81, open: 327.75, high: 327.88, low: 327.75 },
    { time: "2019-01-23", value: 327.5, close: 327.5, open: 327.8, high: 327.8, low: 327.5 },
    { time: "2019-01-24", value: 327.5, close: 327.5, open: 327.49, high: 327.56, low: 327.49 },
    { time: "2019-01-25", value: 327.5, close: 327.5, open: 327.49, high: 327.56, low: 327.49 },
    { time: "2019-01-28", value: 327.55, close: 327.55, open: 327.49, high: 327.57, low: 327.49 },
    { time: "2019-01-29", value: 327.88, close: 327.88, open: 327.54, high: 327.88, low: 327.54 },
    { time: "2019-01-30", value: 327.9, close: 327.9, open: 327.87, high: 327.97, low: 327.87 },
    { time: "2019-01-31", value: 327.97, close: 327.97, open: 327.89, high: 327.97, low: 327.89 },
    { time: "2019-02-01", value: 327.97, close: 327.97, open: 327.96, high: 327.97, low: 327.92 },
    { time: "2019-02-04", value: 327.96, close: 327.96, open: 327.96, high: 327.96, low: 327.93 },
    { time: "2019-02-05", value: 327.9, close: 327.9, open: 327.95, high: 327.98, low: 327.9 },
    { time: "2019-02-06", value: 327.89, close: 327.89, open: 327.89, high: 327.89, low: 327.8 },
    { time: "2019-02-07", value: 327.82, close: 327.82, open: 327.88, high: 327.88, low: 327.82 },
    { time: "2019-02-08", value: 327.8, close: 327.8, open: 327.81, high: 327.88, low: 327.8 },
    { time: "2019-02-11", value: 327.76, close: 327.76, open: 327.79, high: 327.79, low: 327.7 },
    { time: "2019-02-12", value: 327.84, close: 327.84, open: 327.75, high: 327.84, low: 327.75 },
    { time: "2019-02-13", value: 327.85, close: 327.85, open: 327.83, high: 327.87, low: 327.83 },
    { time: "2019-02-14", value: 327.87, close: 327.87, open: 327.84, high: 327.88, low: 327.84 },
    { time: "2019-02-15", value: 327.89, close: 327.89, open: 327.86, high: 327.89, low: 327.8 },
    { time: "2019-02-19", value: 327.9, close: 327.9, open: 327.88, high: 327.98, low: 327.88 },
    { time: "2019-02-20", value: 327.92, close: 327.92, open: 327.89, high: 327.98, low: 327.89 },
    { time: "2019-02-21", value: 327.96, close: 327.96, open: 327.91, high: 327.96, low: 327.91 },
    { time: "2019-02-22", value: 328, close: 328, open: 327.95, high: 328, low: 327.8 },
    { time: "2019-02-25", value: 327.93, close: 327.93, open: 327.99, high: 327.99, low: 327.9 },
    { time: "2019-02-26", value: 327.92, close: 327.92, open: 327.92, high: 327.99, low: 327.91 },
    { time: "2019-02-27", value: 327.87, close: 327.87, open: 327.91, high: 327.91, low: 327.87 },
    { time: "2019-02-28", value: 327.79, close: 327.79, open: 327.86, high: 327.86, low: 327.73 },
    { time: "2019-03-01", value: 327.86, close: 327.86, open: 327.78, high: 327.89, low: 327.78 },
    { time: "2019-03-04", value: 327.94, close: 327.94, open: 327.85, high: 327.98, low: 327.85 },
    { time: "2019-03-05", value: 328.02, close: 328.02, open: 327.93, high: 328.02, low: 327.8 },
    { time: "2019-03-06", value: 327.95, close: 327.95, open: 328.01, high: 328.13, low: 327.9 },
    { time: "2019-03-07", value: 327.8, close: 327.8, open: 327.94, high: 327.94, low: 327.8 },
    { time: "2019-03-08", value: 327.83, close: 327.83, open: 327.79, high: 327.85, low: 327.79 },
    { time: "2019-03-11", value: 327.82, close: 327.82, open: 327.82, high: 327.89, low: 327.82 },
    { time: "2019-03-12", value: 327.75, close: 327.75, open: 327.81, high: 327.81, low: 327.74 },
    { time: "2019-03-13", value: 327.78, close: 327.78, open: 327.74, high: 327.78, low: 327.7 },
    { time: "2019-03-14", value: 327.7, close: 327.7, open: 327.77, high: 327.77, low: 327.7 },
    { time: "2019-03-15", value: 327.68, close: 327.68, open: 327.69, high: 327.69, low: 327.64 },
    { time: "2019-03-18", value: 327.6, close: 327.6, open: 327.67, high: 327.69, low: 327.6 },
    { time: "2019-03-19", value: 327.61, close: 327.61, open: 327.59, high: 327.65, low: 327.59 },
    { time: "2019-03-20", value: 327.45, close: 327.45, open: 327.6, high: 327.6, low: 327.45 },
    { time: "2019-03-21", value: 327.42, close: 327.42, open: 327.44, high: 327.49, low: 327.42 },
    { time: "2019-03-22", value: 327.35, close: 327.35, open: 327.41, high: 327.41, low: 327.3 },
    { time: "2019-03-25", value: 327.38, close: 327.38, open: 327.34, high: 327.38, low: 327.33 },
    { time: "2019-03-26", value: 327.32, close: 327.32, open: 327.37, high: 327.37, low: 327.32 },
    { time: "2019-03-27", value: 327.24, close: 327.24, open: 327.31, high: 327.31, low: 327.2 },
    { time: "2019-03-28", value: 327.26, close: 327.26, open: 327.23, high: 327.28, low: 327.22 },
    { time: "2019-03-29", value: 327.28, close: 327.28, open: 327.25, high: 327.28, low: 327.21 },
    { time: "2019-04-01", value: 327.28, close: 327.28, open: 327.27, high: 327.28, low: 327.2 },
    { time: "2019-04-02", value: 327.27, close: 327.27, open: 327.27, high: 327.29, low: 327.21 },
    { time: "2019-04-03", value: 327.32, close: 327.32, open: 327.26, high: 327.39, low: 327.26 },
    { time: "2019-04-04", value: 327.33, close: 327.33, open: 327.31, high: 327.36, low: 327.31 },
    { time: "2019-04-05", value: 327.36, close: 327.36, open: 327.32, high: 327.37, low: 327.32 },
    { time: "2019-04-08", value: 327.12, close: 327.12, open: 327.35, high: 327.35, low: 327.1 },
    { time: "2019-04-09", value: 327.07, close: 327.07, open: 327.11, high: 327.11, low: 327.02 },
    { time: "2019-04-10", value: 327.13, close: 327.13, open: 327.06, high: 327.18, low: 327.06 },
    { time: "2019-04-11", value: 327.24, close: 327.24, open: 327.12, high: 327.29, low: 327.12 },
    { time: "2019-04-12", value: 327.3, close: 327.3, open: 327.23, high: 327.38, low: 327.23 },
    { time: "2019-04-15", value: 327.48, close: 327.48, open: 327.29, high: 327.49, low: 327.29 },
    { time: "2019-04-16", value: 327.37, close: 327.37, open: 327.47, high: 327.47, low: 327.36 },
    { time: "2019-04-17", value: 327.45, close: 327.45, open: 327.36, high: 327.45, low: 327.36 },
    { time: "2019-04-18", value: 327.41, close: 327.41, open: 327.44, high: 327.49, low: 327.41 },
    { time: "2019-04-22", value: 327.5, close: 327.5, open: 327.4, high: 327.59, low: 327.4 },
    { time: "2019-04-23", value: 327.4, close: 327.4, open: 327.49, high: 327.49, low: 327.4 },
    { time: "2019-04-24", value: 327.35, close: 327.35, open: 327.39, high: 327.39, low: 327.35 },
    { time: "2019-04-25", value: 327.32, close: 327.32, open: 327.34, high: 327.39, low: 327.32 },
    { time: "2019-04-26", value: 327.32, close: 327.32, open: 327.31, high: 327.34, low: 327.31 },
    { time: "2019-04-29", value: 327.28, close: 327.28, open: 327.31, high: 327.31, low: 327.2 },
    { time: "2019-04-30", value: 327.21, close: 327.21, open: 327.27, high: 327.27, low: 327.21 },
    { time: "2019-05-01", value: 327.25, close: 327.25, open: 327.2, high: 327.26, low: 327.2 },
    { time: "2019-05-02", value: 327.24, close: 327.24, open: 327.24, high: 327.26, low: 327.24 },
    { time: "2019-05-03", value: 327.28, close: 327.28, open: 327.23, high: 327.28, low: 327.23 },
    { time: "2019-05-06", value: 327.25, close: 327.25, open: 327.27, high: 327.27, low: 327.2 },
    { time: "2019-05-07", value: 327.24, close: 327.24, open: 327.24, high: 327.29, low: 327.24 },
    { time: "2019-05-08", value: 327.13, close: 327.13, open: 327.23, high: 327.23, low: 327.13 },
    { time: "2019-05-09", value: 327.11, close: 327.11, open: 327.12, high: 327.19, low: 327.11 },
    { time: "2019-05-10", value: 327.11, close: 327.11, open: 327.1, high: 327.18, low: 327.1 },
    { time: "2019-05-13", value: 327.12, close: 327.12, open: 327.1, high: 327.16, low: 327.1 },
    { time: "2019-05-14", value: 327.1, close: 327.1, open: 327.11, high: 327.15, low: 327.1 },
    { time: "2019-05-15", value: 327.15, close: 327.15, open: 327.09, high: 327.19, low: 327.09 },
    { time: "2019-05-16", value: 327.13, close: 327.13, open: 327.14, high: 327.17, low: 327.13 },
    { time: "2019-05-17", value: 327.18, close: 327.18, open: 327.12, high: 327.18, low: 327.12 },
    { time: "2019-05-20", value: 327.2, close: 327.2, open: 327.17, high: 327.23, low: 327.17 },
    { time: "2019-05-21", value: 327.23, close: 327.23, open: 327.19, high: 327.27, low: 327.19 },
    { time: "2019-05-22", value: 327.25, close: 327.25, open: 327.22, high: 327.29, low: 327.2 },
    { time: "2019-05-23", value: 327.27, close: 327.27, open: 327.24, high: 327.27, low: 327.2 },
    { time: "2019-05-24", value: 327.25, close: 327.25, open: 327.26, high: 327.28, low: 327.21 },
    { time: "2019-05-28", value: 327.23, close: 327.23, open: 327.24, high: 327.26, low: 327.23 },
  ];

  var dayData = JSON.parse(JSON.stringify(initialData));

  const initMa = (list, interval) => {
    let len = initialData.length;
    let ma = 0;
    for (let i = len - 1; i >= len - interval; i--) {
      ma += initialData[i].value;
    }
    ma /= interval;
    list.unshift({ time: initialData[len - 1].time, value: ma });
    for (let i = len - 2; i >= len - 100; i--) {
      ma = (ma * interval - initialData[i].value + initialData[i - interval].value) / interval;
      list.unshift({ time: initialData[i].time, value: ma });
    }
    return list;
  };

  const mas = [
    { interval: 8, color: "#ffffff", width: 3, maSeries: undefined, maData: initMa([], 8) },
    { interval: 12, color: "#ffffff", width: 3, maSeries: undefined, maData: initMa([], 12) },
    { interval: 18, color: "#ffffff", width: 3, maSeries: undefined, maData: initMa([], 18) },
    { interval: 24, color: "#ffffff", width: 3, maSeries: undefined, maData: initMa([], 24) },
    { interval: 32, color: "#ffffff", width: 3, maSeries: undefined, maData: initMa([], 32) },
  ];

  const initMas = () => {
    for (let i = 0; i < mas.length; i++) {
      mas[i].maSeries = chart.addLineSeries({
        color: mas[i].color,
        lineWidth: mas[i].width,
      });
      mas[i].maSeries.setData(mas[i].maData);
    }
  };

  const resetMas = () => {
    for (let i = 0; i < mas.length; i++) {
      mas[i].maData = mas[i].maData.splice(-80);
      mas[i].maSeries.setData(mas[i].maData);
    }
  };

  const updateMa = (data, maItem) => {
    const ma = maItem.maData[maItem.maData.length - 1].value;
    const index = j - maItem.interval < 0 ? j - maItem.interval + initialData.length - 1 : j - maItem.interval; // % initialData.length;
    maItem.maData.push({ time: data.time, value: (ma * maItem.interval + data.value - initialData[index].value) / maItem.interval });
    maItem.maSeries.update(maItem.maData[maItem.maData.length - 1]);
  };

  const updateMas = (data) => {
    for (let i = 0; i < mas.length; i++) {
      updateMa(data, mas[i]);
    }
  };

  var chart = createChart(document.getElementById("chart-bg"), {
    width: window.innerWidth,
    height: window.innerHeight,
    layout: {
      backgroundColor: "#004d11",
      textColor: "#d1d4dc",
    },
    grid: {
      vertLines: {
        visible: false,
      },
      horzLines: {
        visible: false,
      },
    },
    rightPriceScale: {
      visible: true,
    },
    timeScale: {
      visible: false,
    },
    crosshair: {
      horzLine: {
        visible: false,
      },
    },
  });

  const histogramAvg = 327.5;
  var histogramSerires = undefined;
  var candlestickSeries = undefined;
  function syncToInterval() {
    candlestickSeries = chart.addCandlestickSeries({
      upColor: "#ffffff",
      wickUpColor: "#ffffff",
      downColor: "#00000088",
      wickDownColor: "#00000088",
      // upColor: "#ffffff",
      // wickUpColor: "#ffffff",
      // downColor: "#00000088",
      // wickDownColor: "#00000088",
      borderVisible: false,
    });
    candlestickSeries.setData(dayData);
    initMas();

    histogramSerires = chart.addHistogramSeries({
      base: histogramAvg,
      // color: "#075d47",
      color: "#ffffff00",
    });
    for (let i = 0; i < dayData.length; i++) {
      if (dayData[i].value < histogramAvg) {
        dayData[i].color = "#00000044";
      }
      if (Math.abs(dayData[i].value - histogramAvg) > 0.3) {
        dayData[i].color = "#88888822";
      }
    }
    histogramSerires.setData(dayData);
  }

  syncToInterval();

  chart.applyOptions({
    timeScale: {
      rightOffset: 0,
      barSpacing: 24,
    },
    handleScale: false,
    grid: {
      vertLines: {
        visible: false,
      },
      horzLines: {
        visible: false,
      },
    },
    layout: {
      // backgroundColor: "#08644c",
      // backgroundColor: "#8bbecc44",
      backgroundColor: "#08644c",
    },
  });

  let j = 0;
  let i = 0;
  setInterval(() => {
    if (!window.bg_scroll) return;

    ++j;
    ++i;
    const data = dayData.shift();

    if (j >= initialData.length) {
      resetMas();
      dayData = dayData.splice(-80);
      histogramSerires.setData(dayData);
      candlestickSeries.setData(dayData);
      dayData = JSON.parse(JSON.stringify(initialData));
      j = 0;
    } else {
      let x = new Date(new Date("2019-05-28").getTime() + i * 86400000);
      data.time = `${x.getFullYear()}-${padZero(x.getMonth() + 1)}-${padZero(x.getDate())}`;
      dayData.push(data);

      if (data.value < histogramAvg) {
        data.color = "#00000044";
      }
      if (Math.abs(data.value - histogramAvg) > 0.3) {
        data.color = "#88888822";
      }
      histogramSerires.update(data);
      candlestickSeries.update(data);

      updateMas(data);
    }

    // chart.timeScale().scrollToPosition(-2, true);
    // chart.timeScale().scrollToRealTime();
  }, 1024);

  const padZero = (v) => {
    return v < 10 ? `0${v}` : v;
  };
};

export default {
  run,
};

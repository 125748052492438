import React from "react";

class CollapsingDiv extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  render() {
    return (
      <div>
        <div style={{ textAlign: this.props.textAlign, marginBottom: this.props.marginBottom, cursor: "pointer" }} onClick={() => this.setState({ show: !this.state.show })}>
          {this.props.title}
        </div>
        {this.state.show && <div style={{ background: "#ffffff24", padding: "16px", marginTop: "16px", marginBottom: "16px" }}>{this.props.children}</div>}
      </div>
    );
  }
}

export default CollapsingDiv;

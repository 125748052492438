import React from "react";

class CollapsingDiv extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  render() {
    return (
      <div>
        <div
          className="support-collapse-box"
          onClick={() => this.setState({ show: !this.state.show })}
        >
          {this.state.show ? " - " : " + "}
          {this.props.title}
        </div>
        {this.state.show && <div>{this.props.children}</div>}
      </div>
    );
  }
}

export default CollapsingDiv;

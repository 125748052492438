import React from "react";

class DamakMapMobile extends React.Component {
    render() {
        return (
            <div style={{ height: "50vh", display: "flex", flexDirection: "column" }}>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d728.2407351233513!2d87.695446999696!3d26.660563940306012!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39e58f0071758c87%3A0x4902641480c0be50!2sJF%20Securities!5e0!3m2!1sen!2snp!4v1702621270436!5m2!1sen!2snp"
                    style={{ width: "100%", flex: "1", fleborder: 0 }}
                    allowfullscreen=""
                    frameborder="0"
                />
                <div
                    style={{
                        paddingLeft: "18px",
                        paddingRight: "18px",
                        paddingTop: "18px",
                        paddingBottom: "18px",
                        textAlign: "center",
                        background: "#000",
                        color: "white",
                        fontSize: "0.85rem",
                    }}
                >
                    Jagriti Marga, Damak, Jhapa , Nepal
                </div>
            </div>
        );
    }
}

export default DamakMapMobile;

import React from "react";

class KathmanduMap extends React.Component {
  render() {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          width: "536px",
        }}
      >
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3532.481974104813!2d85.30652821506197!3d27.702401382793976!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb1855d4115a87%3A0xaf7f483b1acdbaf2!2sJ+F+Securities!5e0!3m2!1sen!2snp!4v1533668319871"
          style={{ width: "100%", flex: "1", fleborder: 0 }}
          allowfullscreen=""
          frameborder="0"
        />
        <div
          style={{
            width: "500px",
            paddingLeft: "18px",
            paddingRight: "18px",
            paddingTop: "18px",
            paddingBottom: "18px",
            textAlign: "center",
            background: "#000",
            color: "white",
            fontSize: "0.85rem",
          }}
        >
          Dharma Path, Newroad, Kathmandu, Nepal
        </div>
      </div>
    );
  }
}

export default KathmanduMap;

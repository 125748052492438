import React from "react";
import ReactDOMServer from "react-dom/server";

class PaymentItem extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      tipContent: ReactDOMServer.renderToString(this.props.tipContent()),
    };
  }

  handleClick() {
    // this.props.url && window.open(this.props.url);
    this.props.clickCallback && this.props.clickCallback(this.props.tipContent());
  }

  renderSmall() {
    return (
      // <div className={`payment-item ${!this.props.url ? "no-link" : ""}`} onClick={this.handleClick}>
      <div className={`payment-item`} onClick={this.handleClick}>
        {this.props.label && <div className="payment-label">{this.props.label}</div>}
        <img className="qr-code" src={this.props.qrcode} />
        {this.props.notes && <div className="note">{this.props.notes}</div>}
      </div>
    );
  }

  renderBig() {
    return (
      <div className={`payment-item-big`} onClick={this.handleClick}>
        <img className="qr-code" src={this.props.qrcode} />
        <div className="note-big">{this.props.notes}</div>
      </div>
    );
  }

  render() {
    const placeholer = this.props.big ? this.renderBig() : this.renderSmall();
    return placeholer;
  }
}

export default PaymentItem;

import React from "react";

class DangMap extends React.Component {
  render() {
    return (
      <div style={{ height: "100vh", display: "flex", flexDirection: "column",width:"536px" }}>
        <iframe
        title="JF Securities Tulsipur Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4975.92757498122!2d82.29225775678401!3d28.1308937556328!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3997f5a4fc42993d%3A0x1738775d9a731c5!2sJF%20Securities%20Tulsipur!5e0!3m2!1sen!2snp!4v1702619585353!5m2!1sen!2snp"
          style={{ width: "100%", flex: "1", border: 0 }}
          allowFullScreen=""
          frameBorder="0"
        />
        <div
          style={{
            width: "500px",
            paddingLeft: "18px",
            paddingRight: "18px",
            paddingTop: "18px",
            paddingBottom: "18px",
            textAlign: "center",
            background: "#000",
            color: "white",
            fontSize: "0.85rem",
          }}
        >
          Tulsipur, Dang , Nepal
        </div>
      </div>
    );
  }
}

export default DangMap;
